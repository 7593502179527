const CONSTANTS = {
  MODAL_TYPE: {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    INFO: "INFO",
  },
  DRAWER_TYPE: {
    AGENT: "AGENT",
    ADD_MEMBER: "ADD_MEMBER",
    EDIT_MEMBER: "EDIT_MEMBER",
  },
  USER_ROLE: {
    ORG_ADMIN: "ORG_ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
  },
  LLL_MODELS: [
    { name: "gpt-3.5-turbo" },
    { name: "gpt-3.5-turbo-0613" },
    { name: "gpt-3.5-turbo-16k" },
    { name: "gpt-3.5-turbo-16k-0613" },
    { name: "gpt-4" },
    { name: "gpt-4-0613" },
  ]
};

Object.freeze(CONSTANTS);

export default CONSTANTS;
