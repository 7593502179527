import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { NavLink as RouterNavLink, useLocation } from "react-router-dom";

import { CONSTANTS } from "../utils/helpers";
import { remToPx } from "../utils/remToPx";

const { USER_ROLE } = CONSTANTS;

const useInitialValue = (value, condition = true) => {
  let initialValue = useRef(value).current;
  return condition ? initialValue : value;
};

const NavLink = ({ to, isInsetLink = false, children }) => {
  return (
    <RouterNavLink
      to={to}
      className={({ isActive }) =>
        clsx(
          "flex justify-between gap-2 py-1 pr-3 text-sm transition",
          isInsetLink ? "pl-7" : "pl-4",
          isActive ? "text-white" : "text-zinc-400 hover:text-white"
        )
      }
    >
      <span className="truncate">{children}</span>
    </RouterNavLink>
  );
};

const ActivePageMarker = ({ group, pathname }) => {
  let itemHeight = remToPx(2);
  let offset = remToPx(0.25);
  let activePageIndex = group.links.findIndex((link) =>
    pathname.startsWith(link.to)
  );
  let top = offset + activePageIndex * itemHeight;

  return (
    <motion.div
      layout
      className="absolute left-2 h-6 w-px bg-purple-500"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0 }}
      style={{ top }}
    />
  );
};

const GroupHighlight = ({ group, pathname }) => {
  let itemHeight = remToPx(2);
  let activePageIndex = group.links.findIndex((link) =>
    pathname.startsWith(link.to)
  );
  let top = activePageIndex * itemHeight;

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0 }}
      className="absolute inset-x-0 top-0 will-change-transform bg-white/2.5"
      style={{ borderRadius: 4, height: itemHeight, top }}
    />
  );
};

const NavigationGroup = ({ group, userRoles, className }) => {
  const location = useLocation();

  let isActiveGroup = group.links?.some((link) =>
    location.pathname.startsWith(link.to)
  );

  return (
    <li
      className={clsx(
        "relative",
        group?.links?.length > 0 ? "mb-4" : "mb-2",
        className
      )}
    >
      <motion.h2 layout="position" className="text-xs font-semibold text-white">
        {group.title}
      </motion.h2>
      {group?.links?.length > 0 && (
        <div className="relative mt-3 pl-2">
          <AnimatePresence initial={true}>
            {isActiveGroup && (
              <GroupHighlight group={group} pathname={location.pathname} />
            )}
          </AnimatePresence>
          <motion.div
            layout
            className="absolute inset-y-0 left-2 w-px bg-white/5"
          />
          <AnimatePresence initial={false}>
            {isActiveGroup && (
              <ActivePageMarker group={group} pathname={location.pathname} />
            )}
          </AnimatePresence>
          <ul role="list" className="border-l border-transparent">
            {group.links
              .filter(
                (block) =>
                  block?.roles?.some((role) => userRoles.includes(role)) ?? true
              )
              .map((link) => (
                <motion.li
                  key={link.title}
                  layout="position"
                  className="relative"
                >
                  <NavLink to={link.to}>{link.title}</NavLink>
                  <AnimatePresence mode="popLayout" initial={false}>
                    {location.pathname.startsWith(link.to) &&
                      link?.links?.length > 0 && (
                        <motion.ul
                          role="list"
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: { delay: 0.1 },
                          }}
                          exit={{
                            opacity: 0,
                            transition: { duration: 0.15 },
                          }}
                        >
                          {link.links.map((section) => (
                            <li key={section.id}>
                              <NavLink
                                to={section.to}
                                tag={section.tag}
                                isInsetLink
                              >
                                {section.title}
                              </NavLink>
                            </li>
                          ))}
                        </motion.ul>
                      )}
                  </AnimatePresence>
                </motion.li>
              ))}
          </ul>
        </div>
      )}
    </li>
  );
};

// THE BELOW MAY NEED TO BE DELETED, WE PLAYED AROUND WITH MANY DIFFERENT NAVIGATION, THIS WAS THE PREVIOUS ONE
// export const navigation = [
//   {
//     title: "Organization",
//     roles:['SUPER_ADMIN'],
//     links: [
//       { title: "Members", to: "admin/organization/members" },
//     ],
//   },
//   {
//     title: "Permissions",
//     roles:['ORG_ADMIN','SUPER_ADMIN'],
//     links: [
//       // { title: "Members", to: "/admin/organization/members" },
//     ],
//   },
//   {
//     title: "Connector/Webhooks",
//     roles:['ORG_ADMIN','SUPER_ADMIN'],
//     links: [
//       // { title: "Members", to: "/admin/organization/members" },
//     ],
//   },
//   {
//     title: "Settings",
//     links: [
//     ],
//   },
// ];

export const navigation = [
  {
    title: "Admin",
    roles: [USER_ROLE.ORG_ADMIN, USER_ROLE.SUPER_ADMIN],
    links: [
      { title: "Agents", to: "/admin/agents" },
      { title: "Organization", to: "/admin/organization" },
      { title: "Permissions", to: "/admin/permissions" },
      { title: "Integrations", to: "/admin/integrations" },
      { title: "Settings", to: "/admin/settings" },
    ],
  },
  {
    title: "Super Admin",
    roles: [USER_ROLE.SUPER_ADMIN],
    links: [{ title: "Organizations", to: "/super-admin/organizations" }],
  },
];

export const Navigation = (props) => {
  const { user } = useAuth0();
  const {
    organization: { roles },
  } = user;

  // const roles = ["SUPER_ADMIN"];
  // const roles = ["ORG_ADMIN"];
  // const roles = ["USER"];

  // THE BELOW MAY NEED TO BE DELETED, WE PLAYED AROUND WITH MANY DIFFERENT NAVIGATION, THIS WAS THE PREVIOUS ONE
  // const { fetcherFn } = useFetchContext();
  // const { data, isLoading, isError, error } = useQuery(["agent"], fetcherFn, {
  //   staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
  // });
  // const [nav, setNav] = useState([])

  // useEffect(() => {
  //   if (!isLoading && data) {
  //     const agents = data.map(agent => {
  //       return {
  //         title: agent.display_name, to: `/admin/context/search/${agent.id}`, links: [
  //           { title: "Search Data", to: `/admin/context/search/${agent.id}`, roles:['ORG_ADMIN','SUPER_ADMIN'], },
  //           { title: "Context Data Entry", to: `/admin/context/entry/${agent.id}`, roles:['ORG_ADMIN','SUPER_ADMIN'], },
  //         ]}
  //     })

  // }, [data, isLoading])

  return (
    <nav {...props}>
      <ul role="list">
        {/* <TopLevelNavItem href="/">API</TopLevelNavItem>
            <TopLevelNavItem href="#">Documentation</TopLevelNavItem>
            <TopLevelNavItem href="#">Support</TopLevelNavItem> */}
        {navigation.length > 0 &&
          navigation
            ?.filter((block) =>
              block?.roles?.some((role) => roles.includes(role))
            )
            .map((group, groupIndex) => (
              <NavigationGroup
                key={group.title}
                group={group}
                userRoles={roles}
                className={groupIndex === 0 && "md:mt-0"}
              />
            ))}
        {/* <li className="sticky bottom-0 z-10 mt-6 min-[416px]:hidden">
              <Button href="#" variant="filled" className="w-full">
                Sign in
              </Button>
            </li> */}
      </ul>
    </nav>
  );
};
