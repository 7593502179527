import { Dialog, Tab, Transition } from "@headlessui/react";
import {
  FingerPrintIcon,
  SwatchIcon as SwatchIconSolid,
} from "@heroicons/react/20/solid";
import {
  ArrowLeftIcon,
  AtSymbolIcon,
  CameraIcon,
  CodeBracketIcon,
  PencilIcon,
  PlusIcon,
  TicketIcon,
  UserIcon,
  WindowIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//internal
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../../../components/Button";
import { Input, SearchCombobox } from "../../../components/Input";
import Loader from "../../../components/Loader";
import { DeletionModal } from "../../../components/Modals";
import { useFetchContext } from "../../../contextProviders/FetchProvider";
import { useGlobal } from "../../../contextProviders/GlobalProvider";
import { useNotificationContext } from "../../../contextProviders/NotificationProvider";
import { CONSTANTS } from "../../../utils/helpers";
import useForm from "../../../utils/hooks/useForms";

export const OverviewTab = (props) => {
  const { data: org } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();

  const queryClient = useQueryClient();

  const { mutate, isLoading: isUpdateLoading } = useMutation({
    mutationFn: ({ id, updates }) => {
      return fetchWrapper(`org/${id}`, "PATCH", {}, updates);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [`agent/${data.id}`],
      });
      const params = {
        success: true,
        msg: "Agent successfully updated!",
      };
      emitNotification(params);
    },
  });

  const { handleSubmit, handleChange, resetForm, isDirty, data, errors } =
    useForm({
      initialValues: {
        name: org.name,
        display_name: org.display_name,
        logo_url: org.branding.logo_url,
        colors_primary: org.branding.colors.primary,
        color_bg: org.branding.colors.page_background,
        api_key: org.api_key,
      },
      validationDebounce: 500,
      validations: {
        name: {
          required: {
            value: true,
            message: "Name required.",
          },
          pattern: {
            value: /^[a-z_0-9]+$/gu,
            message: "Invalid entry. The name must be all be snake_case.",
          },
        },
        display_name: {
          required: {
            value: true,
            message: "Display Name required.",
          },
          pattern: {
            value:
              /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9'.]+([\p{L}\p{M}\p{Zs}0-9'\-.&]+)*/gu,
            message:
              "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
          },
        },
        logo_url: {
          pattern: {
            value:
              /^(?:https?:\/\/)?(?:www\.)?[a-z0-9][a-z0-9\-]*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/.*)?$/i,
            message: "Must be a valid URL",
          },
        },
      },
      onSubmit: async ({
        name,
        display_name,
        logo_url,
        colors_primary,
        color_bg,
      }) => {
        const payload = {
          name,
          display_name,
          logo_url,
          colors_primary,
          color_bg,
        };
        mutate({ id: org.id, updates: payload });
      },
    });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className=" border border-zinc-900/15 dark:border-white/10 divide-y divide-zinc-900/15 dark:divide-white/10 sm:rounded-lg bg-white/40 dark:bg-white/10">
          <div className="px-4 sm:px-9 divide-y divide-zinc-900/10 dark:divide-white/10">
            <div className="md:grid md:grid-cols-5 md:gap-6  pt-5 sm:pt-9 pb-4 ">
              <div className="md:col-span-2">
                <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                  Basic Info
                </h3>
                <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                  Basic details needed to set up your new organization.
                </p>
              </div>
              <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                <div>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    required
                    label="Name"
                    placeholder="chatbot"
                    className="col-span-1"
                    error={errors.name}
                    value={data.name}
                    prepend={
                      <CodeBracketIcon className="h-4 w-4 text-zinc-500" />
                    }
                    onChange={handleChange("name", true)}
                  />
                  <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                    This is any human-readable identifier for the organization
                    that will be used by end-users to direct them to their
                    organization in your agent.
                  </p>
                </div>
                <div>
                  <Input
                    id="display_name"
                    name="diplay_name"
                    type="text"
                    required
                    label="Display Name"
                    placeholder="Chatbot"
                    className="col-span-1"
                    error={errors.display_name}
                    value={data.display_name}
                    prepend={<WindowIcon className="h-4 w-4 text-zinc-500" />}
                    onChange={handleChange("display_name", true)}
                  />
                  <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                    The name that will be displayed to end-users for this
                    organization in any interaction with them.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-5 md:gap-6  pt-5 sm:pt-9 pb-4 ">
              <div className="md:col-span-2">
                <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                  Branding
                </h3>
                <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                  Branding settings associated with your organization.
                </p>
              </div>
              <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                <div>
                  <Input
                    id="logo_url"
                    name="logo_url"
                    type="text"
                    required
                    label="Logo URL"
                    placeholder="chatbot"
                    className="col-span-1"
                    error={errors.logo_url}
                    value={data.logo_url}
                    prepend={<CameraIcon className="h-4 w-4 text-zinc-500" />}
                    onChange={handleChange("logo_url", true)}
                  />
                  <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                    The logo that will be displayed to end-users for this
                    organization in any interaction with them.
                  </p>
                </div>
                <div>
                  <Input
                    id="colors_primary"
                    name="colors_primary"
                    type="text"
                    required
                    label="Primary Color"
                    placeholder="Chatbot"
                    className="col-span-1"
                    error={errors.colors_primary}
                    value={data.colors_primary}
                    prepend={
                      <SwatchIconSolid
                        className="h-4 w-4 text-zinc-500 text-[--primary-color]"
                        style={{
                          "--primary-color": data.colors_primary,
                        }}
                      />
                    }
                    onChange={handleChange("colors_primary", true)}
                  />
                  <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                    The primary color for buttons that will be displayed to
                    end-users for this organization in your agent's
                    authentication flows.
                  </p>
                </div>
                <div>
                  <Input
                    id="color_bg"
                    name="color_bg"
                    type="text"
                    required
                    label="Page Background Color"
                    placeholder="Chatbot"
                    className="col-span-1"
                    error={errors.color_bg}
                    value={data.color_bg}
                    prepend={
                      <SwatchIconSolid
                        className="h-4 w-4 text-zinc-500 text-[--page-background]"
                        style={{
                          "--page-background": data.color_bg,
                        }}
                      />
                    }
                    onChange={handleChange("color_bg", true)}
                  />
                  <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                    The page background color that will be displayed to
                    end-users for this organization in in your agent's
                    authentication flows.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-5 md:gap-6  pt-5 sm:pt-9 pb-4 ">
              <div className="md:col-span-2">
                <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                  Authorization
                </h3>
              </div>
              <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                <div>
                  {/* TODO: Mask the API key and force user to take action to unmask the value */}
                  <Input
                    id="logo_url"
                    name="logo_url"
                    type="text"
                    label="API Key"
                    placeholder="API Key"
                    className="col-span-1"
                    value={data.api_key}
                    disabled
                    prepend={
                      <CodeBracketIcon className="h-4 w-4 text-zinc-500" />
                    }
                  />
                  <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                    Your API key needed for accessing organization Agents and
                    Workflows.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex flex-row justify-end space-x-3 px-4 py-5 sm:px-9">
            <Button
              variant="secondary"
              onClick={() => resetForm()}
              type="button"
              disabled={!isDirty}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!isDirty}
              type="submit"
              loading={isUpdateLoading}
            >
              Update
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export const Modal = (props) => {
  const { isOpen, setIsOpen, orgId } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);

  const sendInvite = async (inviter, email, roles, groups) => {
    setIsLoading(true);
    try {
      const payload = {
        inviter_name: inviter,
        email,
        roles:
          roles.length > 0 ? roles.split(",").map((value) => value.trim()) : [],
        groups:
          groups.length > 0
            ? groups.split(",").map((value) => value.trim())
            : [],
      };
      await fetchWrapper(`account/invite/${orgId}`, "POST", {}, payload);
      const params = {
        success: true,
        msg: "User successfully Invited!",
      };
      emitNotification(params);
      setIsLoading(false);
      return params;
    } catch (err) {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
      setIsLoading(false);
      return params;
    }
  };

  const { handleSubmit, handleChange, resetForm, data, errors } = useForm({
    initialValues: {
      inviter_name: "",
      email: "",
      roles: { id: "ORG_MEMBER", name: "Member" },
      groups: "",
    },
    validationDebounce: 500,
    validations: {
      inviter_name: {
        required: {
          value: true,
          message: "Inviter Name required.",
        },
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9/'.]+([\p{L}\p{M}\p{Zs}0-9/'\-/./&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      email: {
        required: {
          value: true,
          message: "Display Name required.",
        },
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/gu,
          message: "Invalid email.",
        },
      },
    },
    onSubmit: async ({ inviter_name, email, roles, groups }) => {
      const results = await sendInvite(inviter_name, email, roles.id, groups);
      if (results.success) {
        closeModal();
      }
    },
  });

  const closeModal = () => {
    setIsOpen(false);
    resetForm();
  };

  const roles = [
    { id: "ORG_MEMBER", name: "Member" },
    { id: "ORG_ADMIN", name: "Administrator" },
  ];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 pt-40 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-zinc-800 p-8 text-left align-middle shadow-xl transition-all overflow-visible">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-zinc-800 text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50"
                >
                  Member Invitation
                </Dialog.Title>
                <div className="my-3">
                  <p className="text-sm dark:text-zinc-400 text-zinc-500">
                    Invite users to join your organization and start using
                    nexus.
                  </p>
                </div>
                <form className="" onSubmit={handleSubmit}>
                  <div className="relative mx-2 mt-4 space-y-6">
                    <Input
                      id="inviter_name"
                      name="inviter_name"
                      type="text"
                      required
                      label="Inviter Name"
                      placeholder="John Smith"
                      error={errors.inviter_name}
                      value={data.inviter_name}
                      className="col-span-1"
                      prepend={<UserIcon className="h-4 w-4 text-zinc-500" />}
                      onChange={handleChange("inviter_name", true)}
                    />
                    <Input
                      id="email"
                      name="email"
                      type="text"
                      required
                      label="New Member Email"
                      placeholder="john.smith@email.com"
                      error={errors.email}
                      value={data.email}
                      className="col-span-1"
                      prepend={
                        <AtSymbolIcon className="h-4 w-4 text-zinc-500" />
                      }
                      onChange={handleChange("email", true)}
                    />
                    <SearchCombobox
                      data={roles}
                      id="member_role"
                      label="Role"
                      value={data.roles}
                      onChange={handleChange("roles", true)}
                      prepend={<TicketIcon className="h-4 w-4 text-zinc-500" />}
                      error={errors.roles}
                    />
                  </div>

                  <div className="mt-5 sm:mt-6 sm:flex sm:justify-end">
                    <div className="sm:flex sm:flex-row-reverse gap-4">
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Send Invitation
                      </Button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-full border border-zinc-300 bg-white dark:bg-white/10 dark:hover:bg-white/5 dark:border-white/10 px-4 py-1 text-base font-medium text-zinc-400 shadow-sm hover:bg-zinc-100  hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-300 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const MembersTab = (props) => {
  const { data: org } = props;
  const { orgId } = useParams();
  const { fetcherFn } = useFetchContext();
  const {
    data: members,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery([`org/${org.id}/users`], fetcherFn, {
    staleTime: 60000,
    keepPreviousData: true,
  });

  const {
    data: invites,
    isLoading: isInvitesLoading,
    refetch: refetchInvites,
    isRefetching: isRefetchingInvites,
  } = useQuery([`org/${org.id}/invites`], fetcherFn, {
    staleTime: 60000,
    keepPreviousData: true,
  });

  const [selected, setSelected] = useState([]);
  const [selectedInvites, setSelectedInvites] = useState([]);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const { drawer, setDrawer } = useGlobal();

  const userSelected = (checked, id) => {
    if (checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((select) => select != id));
    }
  };
  const userInviteSelected = (checked, id) => {
    if (checked) {
      setSelectedInvites([...selectedInvites, id]);
    } else {
      setSelectedInvites(selectedInvites.filter((select) => select != id));
    }
  };

  const selectedAll = (checked) => {
    let initialMembers = [];
    let initialMembersInvites = [];
    if (checked) {
      initialMembers = members.reduce((accumulator, member) => {
        if (!member?.email.includes("telios")) {
          accumulator.push(member.user_id);
        }
        return accumulator;
      }, []);
      initialMembersInvites = invites?.map((user) => user?.id);
    }

    setSelected(initialMembers);
    setSelectedInvites(initialMembersInvites);
  };

  const { DRAWER_TYPE } = CONSTANTS;

  const handleClick = (id) => {
    setDrawer({
      ...drawer,
      org_id: id,
      isOpen: true,
      type: DRAWER_TYPE.ADD_MEMBER,
      title: "Add new member",
      action: () => {
        refetch();
        refetchInvites();
      },
    });
  };
  const handleClickEdit = (member) => {
    setDrawer({
      ...drawer,
      isOpen: true,
      type: DRAWER_TYPE.EDIT_MEMBER,
      title: "Edit member",
      member: member,
      action: () => {
        refetch();
        refetchInvites();
      },
    });
  };

  if (isLoading || isInvitesLoading) {
    return (
      <div className="mt-20 flex w-full justify-center">
        <Loader className="h-10 w-10 text-zinc-400" />
      </div>
    );
  }

  return (
    <>
      <div className="mx-4 rounded-md border border:border-gray-700 border-zinc-200/70 dark:border-white/10">
        <div className="sm:flex sm:items-center py-4 px-4 dark:bg-transparent bg-zinc-200/70">
          <div className=" sm:flex-auto ">
            <h1 className="text-lg font-semibold leading-6 text-zinc-900 dark:text-white">
              Members
            </h1>
            <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
              Add users as members of your organization.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={() => handleClick(org.id)}
              className="bg-purple-500 py-2 pl-3 pr-4 text-white rounded-sm flex text-sm"
            >
              <PlusIcon className="w-4 h-4 mt-1 mr-1 pl-0" />
              Add member
            </button>
          </div>
        </div>
        <div className=" flow-root sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-2">
              {isRefetching || isRefetchingInvites ? (
                <div className="my-20 flex w-full justify-center">
                  <Loader className="h-10 w-10 text-zinc-400" />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-zinc-900/15 dark:divide-white/10">
                  <thead className="border-y border-zinc-900/15 dark:border-white/10">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-5 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => selectedAll(e.target.checked)}
                          className="bg-white dark:bg-black dark:checked:bg-blue-600 border-gray-500 focus:ring-0 focus:ring-offset-0"
                        />
                        {/* Nueva casilla de verificación */}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-5 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                      >
                        Identifier
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                      ></th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                      >
                        Role
                      </th>
                      {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                    >
                      Role
                    </th> */}
                      {/* <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">View</span>
                    </th>*/}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-zinc-900/15 dark:divide-white/10">
                    {members?.map(
                      (member) =>
                        !member?.roles[0]?.name.includes("SUPER_ADMIN") && (
                          <tr key={member?.user_id} className="group">
                            <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm">
                              <div className="flex items-center">
                                <input
                                  checked={selected.includes(member?.user_id)}
                                  onChange={(e) =>
                                    userSelected(
                                      e.target.checked,
                                      member?.user_id
                                    )
                                  }
                                  type="checkbox"
                                  className="bg-white dark:bg-black dark:checked:bg-blue-600 border-gray-500 focus:ring-0 focus:ring-offset-0"
                                />
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm">
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    referrerPolicy="no-referrer"
                                    src={member?.picture}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-4">
                                  {/* group-hover:text-purple-500 dark:group-hover:text-purple-500 */}
                                  <div className="font-medium dark:text-zinc-300">
                                    {member?.name}
                                  </div>
                                  <div className="text-gray-500">
                                    {member?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className="w-52 truncate inline-block bg-zinc-200 dark:bg-white/20 py-0.5 px-1 rounded border border-zinc-300 dark:border-zinc-600 text-zinc-500 dark:text-zinc-300">
                                {member?.user_id}
                              </span>
                            </td>
                            <td></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span
                                className={`inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800`}
                              >
                                Active
                              </span>
                            </td>
                            <td>
                              <span className="font-medium dark:text-zinc-300 capitalize">
                                {member?.roles[0]?.name
                                  ?.replace(/_/g, " ")
                                  ?.toLowerCase()}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <button onClick={() => handleClickEdit(member)}>
                                <PencilIcon className="h-8 w-8 p-1  rounded" />
                              </button>
                            </td>
                          </tr>
                        )
                    )}
                    {invites.map(
                      (user) =>
                        !user.invitee?.email.includes("telios") && (
                          <tr key={user?.id} className="group">
                            <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm">
                              <div className="flex items-center">
                                <input
                                  checked={selectedInvites.includes(user?.id)}
                                  onChange={(e) =>
                                    userInviteSelected(
                                      e.target.checked,
                                      user?.id
                                    )
                                  }
                                  type="checkbox"
                                  className="bg-white dark:bg-black dark:checked:bg-blue-600 border-gray-500 focus:ring-0 focus:ring-offset-0"
                                />
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm">
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  <div className="h-10 w-10 mx-auto dark:bg-white/10 bg-zinc-200/70 rounded-full text-sm uppercase text-zinc-900 dark:text-white flex justify-center items-center">
                                    <span>
                                      {user?.inviter?.name
                                        ?.split(" ")[0]
                                        ?.charAt(0)}
                                    </span>
                                    <span>
                                      {user?.inviter?.name
                                        ?.split(" ")[1]
                                        ?.charAt(0)}
                                    </span>
                                  </div>
                                </div>
                                <div className="ml-4">
                                  {/* group-hover:text-purple-500 dark:group-hover:text-purple-500 */}
                                  <div className="font-medium dark:text-zinc-300 capitalize">
                                    {user?.inviter?.name}
                                  </div>
                                  <div className="text-gray-500">
                                    {user?.invitee?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className="w-52 truncate inline-block bg-zinc-200 dark:bg-white/20 py-0.5 px-1 rounded border border-zinc-300 dark:border-zinc-600 text-zinc-500 dark:text-zinc-300">
                                {user.id}
                              </span>
                            </td>
                            <td></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span
                                className={`inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800`}
                              >
                                Pending
                              </span>
                            </td>
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <button onClick={() => handleClickEdit(user)}>
                                <PencilIcon className="h-8 w-8 p-1  rounded" />
                              </button>
                            </td> */}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              )}
              {selected?.length + selectedInvites.length > 0 ? (
                <div className="dark:text-zinc-300  bg-zinc-200/70 dark:bg-[#232329] rounded-b-md w-full flex justify-between py-4 px-8 items-center">
                  <span>
                    {selected?.length + selectedInvites.length}
                    {" member(s) selected"}
                  </span>
                  <div>
                    <button
                      onClick={() => setIsDeletionModalOpen(true)}
                      className="py-2 px-4 bg-red-600 text-white"
                    >
                      Delete members
                    </button>
                    <button
                      className="ms-5"
                      onClick={() => {
                        setSelected([]);
                        setSelectedInvites([]);
                      }}
                    >
                      <XMarkIcon width={20} height={20} />
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <DeletionModal
          orgId={orgId}
          selectedDeleteMembers={selected}
          selectedDeleteMembersInvites={selectedInvites}
          actionRefetch={() => {
            setSelected([]);
            setSelectedInvites([]);
            refetch();
            refetchInvites();
          }}
          setIsOpen={setIsDeletionModalOpen}
          isOpen={isDeletionModalOpen}
          entityLabel={"Delete a member(s)"}
          deleteApiUrl={`account/`}
          goBack={false}
        >
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Are you sure you want to delete the member(s) selected?
          </p>
        </DeletionModal>
      </div>
    </>
  );
};

const tabs = [
  { id: "overview", name: "Overview", component: OverviewTab },
  { id: "members", name: "Members", component: MembersTab },
  // {name:'Members', component: null },
];

export const Organization = () => {
  const { user } = useAuth0();
  const { fetcherFn } = useFetchContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { data: org, isLoading } = useQuery(
    [`org/${orgId ? orgId : user.organization.id}`],
    fetcherFn,
    {
      enabled: !!user.organization.id,
    }
  );

  if (isLoading) {
    return (
      <div className="mt-20 flex w-full justify-center">
        <Loader className="h-10 w-10 text-zinc-400" />
      </div>
    );
  }

  return (
    <main className="mx-auto flex w-full min-w-[405px] max-w-7xl flex-col p-10">
      {orgId && (
        <button
          onClick={() => navigate(-1)}
          className="flex flex-row items-center space-x-2 text-sm dark:text-zinc-400 text-zinc-500"
        >
          <ArrowLeftIcon className="h-4 w-4" />{" "}
          <span className="font-medium">
            {state && state.origin
              ? `Back to ${state.origin} `
              : `Back to Organizations`}
          </span>
        </button>
      )}
      <div className="mt-4 ml-6 flex flex-row">
        <div className="w-[64px] h-[64px] border rounded-lg border-zinc-900/15 dark:border-white/10 align-center mr-4 flex dark:bg-zinc-800">
          {/* <img src={org?.branding.logo_url} alt="Company logo" /> */}
          <div
            className="w-[60px] h-[60px] bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${org?.branding.logo_url})` }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <h1 className="text-3xl font-medium text-zinc-900 dark:text-white">
            {org?.display_name}
          </h1>
          <div className="flex flex-row items-center space-x-2 text-sm dark:text-zinc-400 text-zinc-500">
            <span>Organization ID :</span>
            <div className="relative flex rounded shadow-sm ">
              <span className="inline-flex items-center rounded-l border border-r-0 border-zinc-900/15 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-1 dark:text-zinc-400 text-zinc-500">
                <FingerPrintIcon className="h-3 w-3" />{" "}
              </span>
              <span className="rounded-r border border-zinc-900/15 dark:border-white/10 bg-zinc-100 dark:bg-white/10 py-0.5 px-1 text-xs text-zinc-400 dark:text-white">
                {org?.id}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Tab.Group className="mx-6 mt-8" as="nav">
        <Tab.List className="-mb-px flex flex-row space-x-6 border-b border-zinc-900/15 dark:border-white/10">
          {tabs.map((tab) => (
            <Tab
              className="outline-none"
              aria-label="Tabs"
              as={Fragment}
              key={`${tab.id}_list`}
            >
              {({ selected }) => (
                <div
                  key={tab.id}
                  className={clsx(
                    selected
                      ? "border-sky-500 text-sky-500"
                      : "border-transparent text-zinc-500 hover:border-zinc-300 hover:text-zinc-700",
                    "cursor-pointer whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                  )}
                >
                  {tab.name}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="py-8" as="div">
          {tabs.map((tab) => {
            return (
              <Tab.Panel key={`${tab.id}_panel`}>
                <tab.component data={org} />
              </Tab.Panel>
            );
          })}
        </Tab.Panels>
      </Tab.Group>
    </main>
  );
};
