import * as React from 'react';

const Logo = ({
  className = '',
  ...props
}) => (
  <svg
    className={className}
    viewBox="0 0 479 417"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <linearGradient
        x1="8.85951598%"
        y1="85.8776299%"
        x2="91.1746781%"
        y2="14.1422947%"
        id="linearGradient-kzbdkq49is-1"
      >
        <stop stopColor="#A996FF" offset="0%" />
        <stop stopColor="#25C3FF" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Login-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="telios_color_logo" fill="url(#linearGradient-kzbdkq49is-1)" fillRule="nonzero">
            <path d="M386.7,0 L155.5,0 C167.9,7.9 180.4,16.7 193,26.6 C201,32.9 208.7,39.4 216.3,45.9 L386.6,45.9 C411.9,45.9 432.6,66.5 432.6,91.9 L432.6,233.4 C417.3,234.5 401.1,232.8 384.9,228.4 C339.2,215.8 299,183.6 272.7,160.5 C259,148.4 245.3,135.4 232,122.9 C210.6,102.6 188.4,81.5 164.6,62.8 C156.9,56.8 149.3,51.2 141.7,46 C123.9,33.8 106.4,24.1 89.1,16.7 C66,6.9 38.9,12.9 22.4,31.9 C22.3,32 22.2,32.1 22.1,32.2 C17.9,37.1 14.1,42.5 11,48.3 C27.8,47.5 44.2,49.6 59.5,54.5 C60.8,55 62.1,55.4 63.4,55.9 C68.6,57.9 73.9,60.1 79.1,62.6 C97.5,71.3 116.4,83.4 136.1,98.9 C158.3,116.3 178.7,135.7 200.3,156.2 L200.3,156.2 C215.1,170.3 220.5,191.6 214.2,211 L168.4,352.5 C164.8,363.5 154.6,371 143,371 L92,371 C66.7,371 46,350.4 46,325 L46,98.5 C35.1,94.9 23.2,93.5 11.1,94.4 C7.4,94.7 3.7,95.1 0,95.8 L0,325.1 C0,375.9 41.2,417 91.9,417 L386.6,417 C437.3,417 478.500362,375.8 478.500362,325.1 L478.500362,270.7 L478.500362,220.8 L478.500362,91.9 C478.6,41.3 437.3,0 386.7,0 Z M432.6,325.1 C432.6,325.2 432.6,325.4 432.6,325.5 C432.4,350.6 411.9,371.1 386.7,371.1 L233.6,371.1 C222.4,371.1 214.5,360.2 217.9,349.5 L252.3,243.1 C257.1,228.2 274.9,222.2 287.8,231.1 C312.2,248.1 340.9,264 372.8,272.8 C389.8,277.5 406.9,279.8 423.6,279.8 C426.7,279.8 429.7,279.7 432.7,279.6 L432.7,325.1 L432.6,325.1 Z" id="Shape"></path>
        </g>
    </g>
</svg>
);

export default Logo;