import {
  SummaryTab,
  EntryTab,
  SearchTab,
  IntegrationsTab,
  APITab,
} from "../../routes/admin/agents/AgentDetails";
import FileUpload from "../../components/FileUpload";

const tabs = [
  { id: "summary", name: "Summary", component: SummaryTab },
  { id: "files", name: "Files", component: FileUpload },
  { id: "data", name: "Data", component: EntryTab },
  { id: "context_search", name: "Context Search", component: SearchTab },
  { id: "integrations", name: "Integrations", component: IntegrationsTab },
  { id: "API", name: "API", component: APITab },
  // {name:'Members', component: null },
];

export { SummaryTab, EntryTab, SearchTab, IntegrationsTab, APITab, tabs };
