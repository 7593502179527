import clsx from "clsx";
import moment from "moment";
import React from "react";

export const SystemInformation = (props) => {
  const { createdAt, updatedAt, className } = props;
  return (
    <div
      className={clsx(
        "border border-zinc-900/15 dark:border-white/10 bg-white dark:bg-white/10 px-4 py-5 sm:rounded-lg sm:p-6",
        className
      )}
    >
      <div className="md:grid md:grid-cols-5 md:gap-6 md:p-3">
        <div className="md:col-span-2">
          <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
            System Information
          </h3>
          <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
            Information about how the data on the page changed and when.
          </p>
        </div>

          <dl className="mt-5 md:col-span-3 md:mt-0 grid grid-cols-2">
            {/* <div className="">
              <dt className="text-sm font-medium capitalize text-slate-700 dark:text-white">
                Created by
              </dt>
              <dd className="mt-1">
                <span className="rounded border border-gray-300 bg-gray-100 py-0.5 px-1 text-xs text-gray-400">
                  {createdBy}
                </span>
              </dd>
            </div> */}
            <div className=" col-span-1">
              <dt className="text-sm font-medium capitalize text-slate-700 dark:text-white">
                Created at:
              </dt>
              <dd className="mt-1 text-sm text-gray-400">
                {moment(createdAt).format("h:mmA on MMM Do, YYYY")}
              </dd>
            </div>
            {/* <div className="">
              <dt className="text-sm font-medium capitalize text-slate-700 dark:text-white">
                Last updated by:
              </dt>
              <dd className="mt-1">
                <span className="rounded border border-gray-300 bg-gray-100 py-0.5 px-1 text-xs text-gray-400">
                  {updatedBy}
                </span>
              </dd>
            </div> */}
            <div className="col-span-1">
              <dt className="text-sm font-medium capitalize text-slate-700 dark:text-white">
                Last updated at:
              </dt>
              <dd className="mt-1 text-sm text-gray-400">
                {moment(updatedAt).format("h:mmA on MMM Do, YYYY")}
              </dd>
            </div>
          </dl>

      </div>
    </div>
  );
};
