import React, { useEffect, useRef, useState } from "react";

import { CONSTANTS } from "../utils/helpers";

const { MODAL_TYPE } = CONSTANTS;

const Modal = ({
  action,
  isOpen,
  onClose,
  title,
  subtitle,
  type = MODAL_TYPE.CREATE,
  titleValue,
}) => {
  const modalRef = useRef(null);
  const [titleInput, setTitleInput] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      setTitleInput("");
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (titleValue.length > 0) {
      setTitleInput(titleValue);
    }
  }, [titleValue]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-white bg-opacity-25"></div>
      <div
        ref={modalRef}
        className="relative bg-white rounded-lg p-6 w-96 h-72"
      >
        <h2 className="text-2xl font-medium">{title}</h2>
        <p className="text-xs text-gray-500 mb-4">{subtitle}</p>
        <div className="relative">
          <span className="text-2xs text-blue-500 tracking-wider absolute top-0 left-0 -mt-3.5 p-1 ml-2 bg-white">
            Title
          </span>
          <input
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
            className="border-2 border-blue-500 px-4 py-2 w-full rounded-sm focus:border-transparent"
            type="text"
          />
        </div>
        <div className="flex flex-col mt-4">
          <button
            disabled={!titleInput.length}
            className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-sm"
            onClick={() => action(titleInput)}
          >
            {type === MODAL_TYPE.CREATE ? "Create" : "Update"}
          </button>
          <button
            className="bg-white border border-white hover:border-purple-500 text-purple-500 px-4 py-2 rounded-sm mt-2"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
