import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useState } from "react";
import { Button } from "../components/Button";
import ModuleSession from "../components/ModuleSession";
import { useFetchContext } from "../contextProviders/FetchProvider";
import { useGlobal } from "../contextProviders/GlobalProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import { CONSTANTS } from "../utils/helpers";
import { tabs } from "../utils/helpers/tabs";

const { DRAWER_TYPE } = CONSTANTS;

const SubAgentSession = ({ agent, turns }) => {
  const { id } = useParams();
  const { fetcherFn, fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [generatedResponseData, setGeneratedResponseData] = useState(null);
  const { drawer, setDrawer, setSelectedTabDrawer } = useGlobal();
  const filesIndex = tabs.findIndex((tab) => tab.id === "files");

  const { data: sessionDataTemplates } = useQuery(
    [`session/${id}/index`],
    fetcherFn,
    {
      keepPreviousData: false,
      enabled: !!id,
      retry: false,
    }
  );

  const latestTurnByAgent = {};

  turns.forEach((turn) => {
    if (
      !latestTurnByAgent[turn.agent_id] ||
      turn.createdAt > latestTurnByAgent[turn.agent_id].createdAt
    ) {
      latestTurnByAgent[turn.agent_id] = turn;
    }
  });

  const { mutate, isLoading: isCompletionLoading } = useMutation({
    mutationFn: ({ body, agentId }) => {
      return fetchWrapper(`agent/${agentId}/completion`, "POST", {}, body);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
      console.log("Error generating completion");
    },
    onSuccess: (data) => {
      if (data?.response) setGeneratedResponseData(data.response);
    },
  });

  const getLatestDataTemplate = () => {
    const jsonData = sessionDataTemplates.filter(
      (item) => item.agent_ids.indexOf(agent.id) > -1
    );

    const itemArr = [];

    for (const item of jsonData) {
      if (item?.data) itemArr.push(item.data);
    }

    return JSON.stringify(itemArr, null, 2);
  };

  const handleClick = () => {
    setSelectedTabDrawer(filesIndex);
    let jsonData = null;
    if (sessionDataTemplates && sessionDataTemplates.length > 0) {
      jsonData = getLatestDataTemplate();
    }
    setDrawer({
      ...drawer,
      isOpen: true,
      type: DRAWER_TYPE.AGENT,
      title: agent.display_name,
      agentId: agent.id,
      jsonData,
    });
  };

  const handleResponse = () => {
    const body = {
      // input: agent?.prompt || "General Info",
      session_id: id,
    };
    mutate({ body, agentId: agent.id });
  };

  const handleOpenDrawer = () => {
    let jsonData = null;
    if (sessionDataTemplates && sessionDataTemplates.length > 0) {
      jsonData = getLatestDataTemplate() || null;
    }
    setDrawer({
      ...drawer,
      isOpen: true,
      type: DRAWER_TYPE.AGENT,
      title: agent.display_name,
      agentId: agent.id,
      jsonData,
    });
  };

  const renderSubModule = () => {
    if (latestTurnByAgent[agent.id]) {
      return (
        <ModuleSession
          data={latestTurnByAgent[agent.id]}
          key={latestTurnByAgent[agent.id].id}
          sessionId={id}
          agent={agent}
        />
      );
    }

    if (generatedResponseData) {
      return (
        <ModuleSession
          data={null}
          sessionId={id}
          agent={agent}
          firstCompletion={generatedResponseData}
        />
      );
    }

    return (
      <div className="mt-5 mb-8">
        <div className="rounded-t-md bg-[#E5E7EB] dark:bg-[#343541] p-4 flex justify-between">
          <span className="font-semibold">{agent.display_name}</span>
        </div>
        <div className="rounded-b-md bg-white dark:bg-black h-32 p-4 overflow-y-auto custom-scrollbar text-gray-400 dark:text-white border-[#E5E7EB]">
          Please click 'Generate'...
        </div>
      </div>
    );
  };

  return agent ? (
    <div key={agent.id} className="m-10 text-zinc-900 dark:text-white">
      <div className="bg-zinc-100 dark:bg-[#232329]">
        <div className="p-5 flex justify-between pb-4 border-b border-[#6B7280]">
          <span className="text-xl">{agent.display_name}</span>
          <button>
            <ChevronDoubleRightIcon
              className="h-8 w-8 p-1 bg-gray-400 dark:bg-[#343541] rounded text-white"
              onClick={() => handleOpenDrawer()}
            />
          </button>
        </div>
        <div className="p-5">
          {renderSubModule()}
          <div className="mb-5 mt-10">
            {!(
              latestTurnByAgent[agent.id] ||
              generatedResponseData ||
              sessionDataTemplates
            ) && (
              <span className="text-gray-500 text-sm">No files attached</span>
            )}
            <button
              className="bg-[#E5E7EB] dark:bg-[#343541] dark:text-white rounded-md ml-2 px-2 py-1 text-sm cursor-pointer"
              onClick={() => handleClick()}
            >
              Upload a file
            </button>
            {/* 2 files attached */}
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        {!(latestTurnByAgent[agent.id] || generatedResponseData) && (
          <Button
            className="bg-purple-600 py-3 px-16 rounded-sm"
            onClick={handleResponse}
            variant="filled"
            loading={isCompletionLoading}
          >
            Generate
          </Button>
        )}
      </div>
    </div>
  ) : null;
};

export default SubAgentSession;
