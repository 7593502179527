import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import Modal from "../components/Modal.jsx";
import { useFetchContext } from "../contextProviders/FetchProvider.jsx";
import { useGlobal } from "../contextProviders/GlobalProvider";
import { NotificationProvider } from "../contextProviders/NotificationProvider";
import Drawer from "./Drawer.jsx";
import { Header } from "./Header";
import { HeroPattern } from "./HeroPattern";
import Logo from "./Logo";
import { Navigation } from "./Navigation";
import Sessions from "./Sessions";

const Layout = () => {
  const location = useLocation();
  const {
    globalModal,
    setGlobalAgent,
    setSessionsList,
    setIsLoadingSessionList,
  } = useGlobal();
  const { fetcherFn } = useFetchContext();

  const { data: dataAgent } = useQuery(["agent"], fetcherFn, {
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
  });

  const [org_id, setOrg_id] = useState(null);
  const { data, isLoading } = useQuery(
    [`session/org/${org_id}?limit=100`],
    fetcherFn,
    {
      keepPreviousData: true,
      enabled: !!org_id,
    }
  );

  useEffect(() => {
    setIsLoadingSessionList(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (dataAgent) {
      setOrg_id(dataAgent[0]?.org_id);
      setGlobalAgent(dataAgent);
    }
  }, [dataAgent]);

  useEffect(() => {
    if (data) {
      setSessionsList(data);
    }
  }, [data]);

  return (
    <div className="lg:ml-72 xl:ml-80 overflow-hidden">
      <motion.header
        layoutScroll
        className="contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex"
      >
        <div className="contents bg-zinc-900 lg:pointer-events-auto lg:flex lg:flex-col lg:w-72 lg:overflow-y-auto lg:border-r lg:border-white/10 xl:w-80 lg:justify-between">
          <div className="lg:px-6 lg:pt-4 lg:pb-8 overflow-y-auto custom-scrollbar">
            <div className="hidden lg:flex">
              <Link
                to="/"
                aria-label="Home"
                className="flex flex-row items-center"
              >
                <Logo className="h-6" />
                <span className="text-xl text-white ml-3 font-black tracking-wider">
                  TELIOS
                </span>
              </Link>
            </div>
            <Header />
            {location.pathname !== "/" && (
              <>
                {location.pathname.startsWith("/admin") ||
                location.pathname.startsWith("/super-admin") ? (
                  <Navigation className="hidden lg:mt-10 lg:flex-grow lg:flex lg:flex-col lg:justify-between" />
                ) : null}
                {/* <ChatNavigation className="hidden lg:mt-7 lg:flex-grow lg:flex lg:flex-col lg:justify-between" /> */}
                {location.pathname.includes("session") && (
                  <div className="mt-5">
                    <Sessions />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </motion.header>
      <div className="relative h-screen">
        <HeroPattern />
        <div className="relative h-full pt-14">
          <main className="h-full overflow-y-auto custom-scrollbar">
            <NotificationProvider>
              <Outlet />
              <Drawer agent={dataAgent ? dataAgent[0] : null} />
            </NotificationProvider>
          </main>
        </div>
      </div>
      <Modal {...globalModal} />
    </div>
  );
};

export default Layout;
