import { useGoogleLogin } from "@react-oauth/google";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React from "react";
import { Button } from "../../../components/Button";
import { useFetchContext } from "../../../contextProviders/FetchProvider";
import { useNotificationContext } from "../../../contextProviders/NotificationProvider";

const GoogleCard = (props) => {
  const { isActive } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const queryClient = useQueryClient();



  const { mutate } = useMutation({
    mutationFn: ({ type, payload }) => {
      return fetchWrapper(`integration/${type}`, "POST", {}, payload);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [`integrations`],
      });
      const params = {
        success: true,
        msg: "Integration successfully connected!",
      };
      emitNotification(params);
    },
  });

  const googleConnect = useGoogleLogin({
    onSuccess: (data) => {
      mutate({ type: "google_drive", payload: { code: data.code } });
    },
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.readonly",
    redirect_uri: "postmessage",
  });
  return (
    <div className="border border-zinc-900/15 dark:border-white/10 rounded py-4 px-2 flex flex-col space-y-2">
      <div className="flex flex-row">
        <img src="/gdrive.png" className="h-10 ml-2 mr-4 mt-1" />
        <div className="flex flex-col">
          <h2 className="font-semibold text-zinc-900 dark:text-white">
            Google Drive
          </h2>
          <span className="text-xs font-light text-zinc-700 dark:text-white/50">
            SOCIAL CONNECTION
          </span>
        </div>
      </div>
      <div className="ml-2 text-sm text-zinc-600 dark:text-zinc-400">
        Allow agents to connect to your organization google drive data.
      </div>
      <div className="flex flex-row-reverse mx-2">
        <Button
          type="button"
          onClick={() => googleConnect()}
          variant="secondary"
          check={isActive && "left"}
          className={clsx(isActive && "text-emerald-500 dark:text-emerald-500 bg-emerald-500/20 dark:bg-emerald-500/20 hover:bg-emerald-500/30 hover:dark:bg-emerald-500/30 dark:ring-emerald-800")}
        >
          {isActive? "Connected":"Connect"}
        </Button>
      </div>
    </div>
  );
};

export const Integrations = () => {
  const { fetcherFn } = useFetchContext();
  const { data } = useQuery([`integrations`], fetcherFn);

  const hasGoogleDrive =
    data && data.some((item) => item.id === "google_drive");

  return (
    <>
      <main className="mx-auto flex w-full min-w-[405px] max-w-7xl flex-col p-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-zinc-900 dark:text-white">
                Integration Connections
              </h1>
              <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
                Below is a list of integrations that can be setup for agents to
                leverage alolngside a language learning model.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-4 px-4 sm:px-6 lg:px-8">
          <GoogleCard isActive={hasGoogleDrive} />
        </div>
      </main>
    </>
  );
};
