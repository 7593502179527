import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

//Internal Component
import { useFetchContext } from "../contextProviders/FetchProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import { Button } from "./Button";

export const DeletionModal = (props) => {
  const {
    actionRefetch = () => {},
    selectedDeleteMembers = [],
    selectedDeleteMembersInvites = [],
    orgId = "",
    isOpen,
    setIsOpen,
    deleteApiUrl,
    invalidationArray = [],
    entityLabel = "Object",
    children,
    setIsBulkDeletionConfirm = () => {},
    isDeleting = false,
    navigateTo = null,
    goBack = true,
  } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      if (
        selectedDeleteMembers?.length > 0 ||
        selectedDeleteMembersInvites?.length > 0
      ) {
        selectedDeleteMembersInvites.map((id) => {
          fetchWrapper(
            `${deleteApiUrl}invite`,
            "DELETE",
            {},
            { orgId: orgId, invitationId: id }
          );
        });

        selectedDeleteMembers?.map((id) => {
          fetchWrapper(`${deleteApiUrl}${id}`, "DELETE");
        });
      } else {
        fetchWrapper(`${deleteApiUrl}`, "DELETE");
      }
      return true;
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [...invalidationArray],
      });
      const params = {
        success: true,
        msg: `${entityLabel} successfully deleted!`,
      };
      if (
        selectedDeleteMembers?.length > 0 ||
        selectedDeleteMembersInvites?.length > 0
      ) {
        actionRefetch();
      }

      emitNotification(params);
      goBack && navigate(navigateTo || -1);
      setIsOpen(false);
    },
  });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 pt-40 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-zinc-900 text-left align-middle shadow-xl transition-all">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-daylight focus:ring-offset-2"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="dark:bg-white/2.5 p-8">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 dark:bg-inherit dark:ring-1 dark:ring-red-500 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600 dark:text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-zinc-900 dark:text-white"
                      >
                        {`Delete ${entityLabel}`}
                      </Dialog.Title>
                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <Button
                      variant="danger"
                      className="ml-4"
                      loading={isLoading || isDeleting}
                      onClick={() => {
                        if (deleteApiUrl) {
                          return mutate();
                        } else {
                          setIsBulkDeletionConfirm(true);
                          setIsOpen(false);
                        }
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
