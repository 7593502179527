import { useState, useEffect } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";

export const Textarea = (props) => {
  const {
    label,
    onChange: onChangeFromProps,
    id,
    name,
    rows = 4,
    required = false,
    error = "",
    placeholder = "",
    value: valueFromProps,
    optional = false,
    defaultValue,
    disabled = false,
  } = props;

  // A component can be considered controlled when its value prop is
  // not undefined.
  const isControlled = typeof valueFromProps != "undefined";
  // When a component is not controlled, it can have a defaultValue.
  const hasDefaultValue = typeof defaultValue != "undefined";
  // If a defaultValue is specified, we will use it as our initial
  // state.  Otherwise, we will simply use an empty string.
  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? defaultValue : ""
  );
  // Internally, we need to deal with some value. Depending on whether
  // the component is controlled or not, that value comes from its
  // props or from its internal state.
  const value = isControlled ? valueFromProps : internalValue;

  const onChange = (e) => {
    // When the user types, we will call props.onChange if it exists.
    // We do this even if there is no props.value (and the component
    // is uncontrolled.)
    if (onChangeFromProps) {
      onChangeFromProps(e);
    }
    // If the component is uncontrolled, we need to update our
    // internal value here.
    if (!isControlled) {
      setInternalValue(e.target.value);
    }
  };

  return (
    <div>
      {label.length > 0 && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
        >
          {label}{" "}
          {optional && (
            <span className="text-xs text-zinc-400">(Optional)</span>
          )}
        </label>
      )}
      <div className="mt-1 relative">
        <textarea
          rows={rows}
          id={id}
          name={name}
          className="block w-full rounded-md dark:text-white border-zinc-900/15 dark:border-white/10 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm placeholder-zinc-400 dark:placeholder-zinc-500 dark:bg-white/10"
          value={value}
          required={required}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div className="absolute -bottom-6 left-3 flex items-center justify-start text-xs">
          <div className="text-red-600">{error}</div>
        </div>
      </div>
    </div>
  );
};

export const Input = (props) => {
  const {
    label = "",
    onChange: onChangeFromProps,
    id,
    name,
    type,
    autoComplete = "",
    required = false,
    error = "",
    placeholder = "",
    value: valueFromProps,
    prepend,
    append,
    optional = false,
    disabled = false,
    defaultValue,
    className,
  } = props;

  // A component can be considered controlled when its value prop is
  // not undefined.
  const isControlled = typeof valueFromProps != "undefined";
  // When a component is not controlled, it can have a defaultValue.
  const hasDefaultValue = typeof defaultValue != "undefined";
  // If a defaultValue is specified, we will use it as our initial
  // state.  Otherwise, we will simply use an empty string.
  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? defaultValue : ""
  );
  // Internally, we need to deal with some value. Depending on whether
  // the component is controlled or not, that value comes from its
  // props or from its internal state.
  const value = isControlled ? valueFromProps : internalValue;

  const handleChange = (e) => {
    // When the user types, we will call props.onChange if it exists.
    // We do this even if there is no props.value (and the component
    // is uncontrolled.)
    if (onChangeFromProps) {
      onChangeFromProps(e);
    }
    // If the component is uncontrolled, we need to update our
    // internal value here.
    if (!isControlled) {
      setInternalValue(e.target.value);
    }
  };

  return (
    <div className={className}>
      {label.length > 0 && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
        >
          {label}{" "}
          {optional && (
            <span className="text-xs text-zinc-400">(Optional)</span>
          )}
        </label>
      )}
      <div className="mt-1 flex rounded-md shadow-sm relative dark:shadow-md ">
        {prepend && (
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-zinc-900/15 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-3 dark:text-zinc-400 text-zinc-500 sm:text-sm">
            {prepend}
          </span>
        )}
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          className={clsx(
            "disabled:bg-neutral-200 disabled:cursor-not-allowed dark:disabled:bg-zinc-900/20 dark:disabled:text-white/50 dark:text-white disabled:text-neutral-400 appearance-none block w-full px-3 py-2 border border-zinc-900/15 dark:border-white/10 placeholder-zinc-400 dark:placeholder-zinc-500 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm dark:bg-white/10",
            prepend && append && "rounded-none border-r-0",
            prepend && !append && "rounded-none rounded-r-md",
            !prepend && append && "rounded-none rounded-l-md",
            !prepend && !append && "rounded-md"
          )}
        />
        {append && (
          <span
            className={clsx(
              disabled && "bg-neutral-50",
              "inline-flex items-center rounded-r-md border border-l-0 border-zinc-900/15 dark:border-white/10 px-1 text-zinc-500 sm:text-sm"
            )}
          >
            {append}
          </span>
        )}
        <div className="absolute -bottom-6 left-3 flex items-center justify-start text-xs">
          <div className="text-red-600">{error}</div>
        </div>
      </div>
    </div>
  );
};

export const SearchCombobox = (props) => {
  const {
    data,
    id,
    label,
    defaultValue,
    value,
    onChange,
    prepend,
    append,
    error = "",
    placeholder = "",
    disabled = false,

    optional = false,
  } = props;
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const filteredValue =
    query === ""
      ? data
      : data.filter((obj) => {
          return obj.name.toLowerCase().includes(query.toLowerCase());
        });

  function handleSelect(value) {
    setSelectedValue(value);
    if (onChange) {
      onChange({ target: { value } });
    }
  }

  return (
    <Combobox as="div" value={selectedValue} onChange={handleSelect}>
      <Combobox.Label
        htmlFor={id}
        className="block text-sm font-medium capitalize text-slate-700 dark:text-white"
      >
        {label}{" "}
        {optional && <span className="text-xs text-gray-400">(Optional)</span>}
      </Combobox.Label>
      <div className="relative mt-1">
        <div className="relative mt-1 flex rounded-md shadow-sm dark:shadow-md dark:shadow-primary-blue-500/50 ">
          {prepend && (
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-zinc-900/15 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-3 dark:text-zinc-400 text-zinc-500 sm:text-sm">
              {prepend}
            </span>
          )}
          <Combobox.Input
            id={id}
            // className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            className={clsx(
              "block w-full appearance-none border px-3 py-2 focus:border-primary-blue-500 focus:outline-none focus:ring-primary-blue-500 border-zinc-900/15 dark:border-white/10 placeholder-zinc-400 dark:placeholder-zinc-500 focus:ring-purple-500 focus:border-purple-500 sm:text-sm dark:bg-white/10 text-slate-700 dark:text-white",
              prepend && append && "rounded-none border-r-0",
              prepend && !append && "rounded-none rounded-r-md",
              !prepend && append && "rounded-none rounded-l-md",
              !prepend && !append && "rounded-md"
            )}
            placeholder={placeholder}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(obj) => obj?.name}
          />
          {append && (
            <span
              className={clsx(
                disabled && "bg-neutral-50",
                "inline-flex items-center rounded-r-md border border-l-0 border-zinc-900/15 dark:border-white/10 px-1 text-zinc-500  sm:text-sm"
              )}
            >
              {append}
            </span>
          )}
          <div className="absolute -bottom-7 left-3 flex items-center justify-start text-xs">
            <div className="text-red-600">{error}</div>
          </div>
        </div>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {data && filteredValue.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-700 text-slate-700 dark:text-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredValue.map((obj) => (
              <Combobox.Option
                key={obj.id}
                value={obj}
                className={({ active }) =>
                  clsx(
                    "relative cursor-default select-none py-2 pl-8 pr-4",
                    active
                      ? "bg-purple-500/20 text-purple-800"
                      : "text-gray-900 dark:text-white"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={clsx(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {obj.name}
                    </span>

                    {selected && (
                      <span
                        className={clsx(
                          "absolute inset-y-0 left-0 flex items-center pl-1.5",
                          active
                            ? "text-purple-600 dark:text-white"
                            : "text-purple-500 dark:text-white"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export const Checkbox = (props) => {
  const {
    label,
    id,
    name,
    description = "",
    className,
    checked: controlledChecked,
    defaultChecked: uncontrolledDefaultChecked,
    onChange: controlledOnChange,
  } = props;

  const [uncontrolledChecked, setUncontrolledChecked] = useState(
    uncontrolledDefaultChecked || false
  );

  const checked =
    controlledChecked !== undefined ? controlledChecked : uncontrolledChecked;
  const onChange =
    controlledOnChange !== undefined
      ? controlledOnChange
      : (e) => setUncontrolledChecked(e.target.checked);

  return (
    <div className={clsx("relative flex items-start", className)}>
      <div className="flex h-6 items-center">
        <input
          id={id}
          aria-describedby={`${name}-description`}
          name={name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor="candidates" className="font-medium text-gray-900">
          {label}
        </label>{" "}
        <span id="candidates-description" className="text-gray-500">
          <span className="sr-only">{label}</span>
          {description}
        </span>
      </div>
    </div>
  );
};

export default SearchCombobox;
