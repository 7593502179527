import React, { Fragment, useState } from "react";

//external librairies
import { Dialog, Transition } from "@headlessui/react";
import {
  SwatchIcon as SwatchIconSolid,
} from "@heroicons/react/20/solid";
import {
  CameraIcon,
  CodeBracketIcon,
  WindowIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

// INTERNAL
import Loader from "../../components/Loader";
import { useFetchContext } from "../../contextProviders/FetchProvider";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { useNotificationContext } from "../../contextProviders/NotificationProvider";
import useForm from "../../utils/hooks/useForms";

const Row = (props) => {
  const { data } = props;
  const { id, name, display_name, branding } = data;

  const navigate = useNavigate();

  const goToView = () => {
    navigate(`./${id}`);
  };

  return (
    <tr
      key={id}
      className="group hover:bg-zinc-100 dark:hover:bg-white/5 cursor-pointer bg-white dark:bg-white/2.5"
      onClick={goToView}
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-2">
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0 mx-4">
            <img
              className="h-10 w-10 rounded-lg"
              src={branding.logo_url}
              alt=""
            />
          </div>
          <div className="">
            <div className="font-medium dark:text-zinc-300 group-hover:text-purple-500 dark:group-hover:text-purple-500">
              {display_name}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-400">
        <div>{name}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-zinc-600 dark:text-zinc-400">
        <span className="bg-zinc-200 dark:bg-white/20 py-0.5 px-1 rounded border border-zinc-300 dark:border-zinc-900/15 text-zinc-500 dark:text-zinc-300">
          {id}
        </span>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-400">
        {/* <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                Active
            </span> */}
      {/* </td> */}
      <td className="relative whitespace-nowrap py-4 pr-3 text-right text-sm font-medium"></td>
    </tr>
  );
};

const Modal = (props) => {
  const { isOpen, setIsOpen } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ payload }) => {
      return fetchWrapper("org", "POST", {}, payload);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [`org`],
      });
      const params = {
        success: true,
        msg: "Organization successfully created!",
      };
      emitNotification(params);
    },
    onSettled: () => {
      closeModal();
    },
  });

  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
  } = useForm({
    initialValues: {
      name: "",
      display_name: "",
      logo_url: "",
      color_primary: "",
      color_bg: "",
    },
    validationDebounce: 500,
    validations: {
      name: {
        required: {
          value: true,
          message: "Name required.",
        },
        pattern: {
          value: /^[a-z_0-9]+$/gu,
          message: "Invalid entry. The name must be all be snake_case.",
        },
      },
      display_name: {
        required: {
          value: true,
          message: "Display Name required.",
        },
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9'.]+([\p{L}\p{M}\p{Zs}0-9'\-./&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      logo_url: {
        pattern: {
          value: /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i,
          message: "Invalid entry. Provide a valid URL.",
        },
      },
      color_primary: {
        pattern: {
          value: /^#([0-9a-f]{3}){1,2}$/i
,
          message: "Invalid entry. This must be a hex color",
        },
      },
      color_bg: {
        pattern: {
          value: /^#([0-9a-f]{3}){1,2}$/i
,
          message: "Invalid entry. This must be a hex color",
        },
      },
    },
    onSubmit: async ({
      name,
      display_name,
      logo_url,
      color_primary,
      color_bg,
    }) => {
      const payload = {
        name,
        display_name,
        logo_url,
        color_primary,
        color_bg,
      };
      mutate({ payload });
    },
  });

  const closeModal = () => {
    setIsOpen(false);
    resetForm();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 pt-40 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-zinc-800 p-8 text-left align-middle shadow-xl transition-all">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-zinc-800 text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50"
                >
                  Organization Creation
                </Dialog.Title>
                <div className="my-3">
                  <p className="text-sm dark:text-zinc-400 text-zinc-500">
                    Create a new Organization, with its members, agents and
                    associated data.
                  </p>
                </div>
                <form className="" onSubmit={handleSubmit}>
                  <div className="relative mx-2 mt-4 space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        id="display_name"
                        name="diplay_name"
                        type="text"
                        required
                        label="Display Name"
                        placeholder="Acme, Inc"
                        className="col-span-1"
                        error={errors.display_name}
                        value={data.display_name}
                        prepend={
                          <WindowIcon className="h-4 w-4 text-zinc-500" />
                        }
                        onChange={handleChange("display_name", true)}
                      />
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        required
                        label="Name"
                        placeholder="acme_inc"
                        error={errors.name}
                        value={data.name}
                        className="col-span-1"
                        prepend={
                          <CodeBracketIcon className="h-4 w-4 text-zinc-500" />
                        }
                        onChange={handleChange("name", true)}
                      />
                    </div>
                    <div>
                      <Input
                        id="logo_url"
                        name="logo_url"
                        type="text"
                        required
                        label="Logo URL"
                        placeholder="https://acme.com/logo.png"
                        className="col-span-1"
                        error={errors.logo_url}
                        value={data.logo_url}
                        prepend={
                          <CameraIcon className="h-4 w-4 text-zinc-500" />
                        }
                        onChange={handleChange("logo_url", true)}
                      />
                      <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                        The logo that will be displayed to end-users for this
                        organization in any interaction with them.
                      </p>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Input
                        id="color_primary"
                        name="color_primary"
                        type="text"
                        required
                        label="Primary Color"
                        placeholder="#FFFFF"
                        className="col-span-1"
                        error={errors.color_primary}
                        value={data.color_primary}
                        prepend={
                          <SwatchIconSolid
                            className="h-4 w-4 text-zinc-500 text-[var(--primary-color)]"
                            style={{
                              "--primary-color": data.color_primary,
                            }}
                          />
                        }
                        onChange={handleChange("color_primary", true)}
                      />
                      <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                        The primary color for buttons that will be displayed to
                        end-users for this organization in your agent's
                        authentication flows.
                      </p>
                    </div>
                    <div>
                      <Input
                        id="color_bg"
                        name="color_bg"
                        type="text"
                        required
                        label="Page Background Color"
                        placeholder="#FFFFF"
                        className="col-span-1"
                        error={errors.color_bg}
                        value={data.color_bg}
                        prepend={
                          <SwatchIconSolid
                            className="h-4 w-4 text-zinc-500 text-[var(--page-background)]"
                            style={{
                              "--page-background": data.color_bg,
                            }}
                          />
                        }
                        onChange={handleChange("color_bg", true)}
                      />
                      <p className="mt-6 text-sm dark:text-zinc-400 text-zinc-500">
                        The page background color that will be displayed to
                        end-users for this organization in in your agent's
                        authentication flows.
                      </p>
                    </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:flex sm:justify-end">
                    <div className="sm:flex sm:flex-row-reverse gap-4">
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Create Organization
                      </Button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-full border border-zinc-300 bg-white dark:bg-white/10 dark:hover:bg-white/5 dark:border-white/10 px-4 py-1 text-base font-medium text-zinc-400 shadow-sm hover:bg-zinc-100  hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-300 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const Organizations = () => {
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const { fetcherFn } = useFetchContext();
  const {
    data: orgList,
    isLoading,
    isPreviousData,
  } = useQuery(["org"], fetcherFn, {
    staleTime: 60000,
    keepPreviousData: true,
  });

  return (
    <>
      <main className="mx-auto flex w-full min-w-[405px] max-w-7xl flex-col p-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-zinc-900 dark:text-white">
                Organizations
              </h1>
              <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
                A list of all the organizations registered
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                type="button"
                onClick={() => setIsRegistrationModalOpen(true)}
                plus="left"
              >
                Add Organization
              </Button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="rounded-xl overflow-hidden border border-zinc-900/15 dark:border-white/10">
                  <table className="min-w-full divide-y divide-zinc-900/15 dark:divide-white/10 ">
                    <thead className=" dark:bg-white/10 bg-zinc-200/70">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-white sm:pl-6"
                        >
                          Display Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                        >
                          Identifier
                        </th>
                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                        >
                          Created Date
                        </th> */}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-zinc-900/15 dark:divide-white/10">
                      {!isLoading &&
                        orgList &&
                        Array.isArray(orgList) &&
                        orgList?.map((org) => <Row key={org.id} data={org} />)}
                      {isLoading && (
                        <tr>
                          <td colSpan="6">
                            <div className="my-20 flex w-full justify-center">
                              <Loader className="h-10 w-10 text-zinc-400" />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        isOpen={isRegistrationModalOpen}
        setIsOpen={setIsRegistrationModalOpen}
      />
    </>
  );
};
