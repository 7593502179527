import { useAuth0 } from "@auth0/auth0-react";
import { PaperAirplaneIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import { io } from "socket.io-client";

import IntroPrompt from "../components/IntroPrompt";
import ResponsePrompt from "../components/ResponsePrompt";
import UserPrompts from "../components/UserPrompts";
import { useFetchContext } from "../contextProviders/FetchProvider";

const Chat = () => {
  const textareaRef = useRef(null);

  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [socket, setSocket] = useState(null);
  const [err, setErr] = useState(false);

  const { fetcherFn } = useFetchContext();
  const { agentId } = useParams();
  const { data, isLoading } = useQuery(["agent"], fetcherFn, {
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  const [currentAgent, setCurrentAgent] = useState();

  useEffect(() => {
    if (!isLoading && data) {
      setCurrentAgent(data.find((group) => group.id === agentId));
    }
  }, [data, isLoading, agentId]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    const resize = () => {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    };
    if (textareaRef.current) {
      textareaRef.current.addEventListener("input", resize, false);
      // Ensure textarea is resized initially
      resize();
    }

    // Cleanup after component is unmounted
    return () => {
      if (textareaRef.current) {
        textareaRef.current.removeEventListener("input", resize, false);
      }
    };
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  let socketUrl = "http://localhost:4005";

  if (window.location.host === "platform.telios.io") {
    socketUrl = "https://api.telios.io";
  } else if (window.location.host === "staging.telios.io") {
    socketUrl = "https://api-staging.telios.io";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
    async function callAPI() {
      try {
        let message = "";

        const initSocket = () => {
          return new Promise(async (resolve, reject) => {
            const token = await getAccessTokenSilently();

            // TODO: Replace hardcoded url with env params
            const _socket = io(socketUrl, {
              path: "/socket.io/",
              reconnectionDelayMax: 10000,
              withCredentials: true,
              auth: {
                token,
                namespace: currentAgent.id,
              },
            });

            _socket.on("connect", () => {
              setSocket(_socket);
              resolve(_socket);
            });

            _socket.emit("prompt-input", { query: inputPrompt });

            _socket.on("completion-text", (text) => {
              message += text;

              setChatLog([
                ...chatLog,
                {
                  chatPrompt: inputPrompt,
                  botMessage: message,
                },
              ]);
            });

            _socket.on("completion-error", (err) => {
              setErr(err);
            });

            _socket.on("agent", (data) => {
              console.log(data);
            });

            _socket.on("completion-done", () => {
              return resolve();
            });
          });
        };

        if (!socket) {
          await initSocket();
        } else {
          socket.emit("prompt-input", { query: inputPrompt });

          socket.on("completion-text", (text) => {
            message += text;

            setChatLog([
              ...chatLog,
              {
                chatPrompt: inputPrompt,
                botMessage: message,
              },
            ]);
          });

          socket.on("completion-done", () => {
            return true;
          });
        }

        setErr(false);
      } catch (err) {
        setErr(err);
      }
    }
    callAPI();
    setInputPrompt("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
      textareaRef.current.style.height = "24px";
    }
  };
  return (
    <div className="h-full w-full flex justify-center">
      <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
        {chatLog.length > 0 && (
          <div className="text-center text-sm py-1 w-full border-b border-black/10 dark:border-[#444654]/20 text-zinc-900 dark:text-gray-400 group bg-zinc-200/20 dark:bg-[#444654]/4">
            Agent: {currentAgent?.display_name}
          </div>
        )}
        <div className="flex-1 overflow-hidden">
          <ScrollToBottom className="h-full">
            <div className="relative flex flex-col items-center text-sm">
              {/* <HeroPattern /> */}
              {/* <div className="w-full h-32 flex-shrink-0"></div> */}
              {chatLog.length === 0 && (
                <IntroPrompt setPrompt={setInputPrompt} />
              )}
              {chatLog.length > 0 &&
                chatLog.map((chat, idx) => (
                  <div key={`log-${idx}`} className="w-full">
                    {chat.chatPrompt.length !== 0 && (
                      <UserPrompts
                        key={`prompt-${idx}`}
                        prompt={chat.chatPrompt}
                      />
                    )}
                    <ResponsePrompt
                      key={`response-${idx}`}
                      markdown={chat.botMessage}
                      error={err}
                    />
                  </div>
                ))}
              <div className="w-full h-32 md:h-48 flex-shrink-0"></div>
            </div>
          </ScrollToBottom>
        </div>
        <div className="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-zinc-800 md:!bg-transparent dark:md:bg-vert-dark-gradient">
          <form
            onSubmit={handleSubmit}
            className="stretch mx-2 flex flex-row gap-3 pt-2 last:mb-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-6"
          >
            <div className="relative flex h-full flex-1 md:flex-col">
              <div className="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-zinc-900/50 dark:text-white dark:bg-zinc-700/80 rounded-md shadow-[0_0_3px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
                <textarea
                  ref={textareaRef}
                  tabIndex="0"
                  name="inputPrompt"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                  rows="1"
                  placeholder=""
                  onKeyDown={handleKeyDown}
                  value={inputPrompt}
                  onChange={(e) => setInputPrompt(e.target.value)}
                  className="m-0 w-full resize-none border-0 bg-transparent p-0 pl-2 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0 font-medium"
                ></textarea>
                <button
                  type="submit"
                  className="absolute p-1 rounded-md text-gray-500 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-zinc-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent"
                >
                  <PaperAirplaneIcon className="h-5 w-5 ml-1 mb-1 -rotate-45" />
                </button>
              </div>
              <div className="flex ml-1 mt-1.5 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center"></div>
            </div>
            {chatLog.length > 0 && (
              <div className="relative">
                <button
                  type="button"
                  onClick={() => setChatLog([])}
                  className="relative top-[10px] p-1 rounded-md text-gray-500 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-zinc-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
