// This code came from the https://github.com/WebClients/packages/components/hooks/useHandler.ts
// Which is under an open source license
import { useRef, useEffect, useMemo } from 'react';
import { debounce, throttle } from '../helpers/function.js';

/**
 * Create a stable reference of handler
 * But will always run the updated version of the handler in argument
 */
export const useHandler = (handler, options = {}) => {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  const actualHandler = useMemo(() => {
    const handler = (...args) => handlerRef.current(...args);

    if (options.debounce && options.debounce > 0) {
      return debounce(handler, options.debounce);
    }

    if (options.throttle && options.throttle > 0) {
      return throttle(handler, options.throttle);
    }

    return handler;
  }, [options.debounce, options.throttle]);

  return actualHandler;
};
