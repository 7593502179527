import { Listbox, Transition } from "@headlessui/react";
import {
  BoltIcon,
  CheckIcon,
  ChevronDownIcon,
  ExclamationTriangleIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchContext } from "../contextProviders/FetchProvider";

const IntroPrompt = (props) => {
  const { setPrompt } = props;
  const { fetcherFn } = useFetchContext();
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery(["agent"], fetcherFn, {
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!isLoading && data) {
      setSelected(data.find((group) => group.id === agentId));
    }
  }, [data, isLoading, agentId]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const switchAgent = (selected) => {
    setSelected(selected);
    navigate(`/${selected.id}`);
  };

  return (
    <>
      <div className="relative z-10 text-gray-800 w-full md:max-w-2xl lg:max-w-3xl md:h-full md:flex md:flex-col px-6 dark:text-gray-100">
        {/* <h1 className="text-4xl font-semibold text-center mt-6 sm:mt-[5vh] ml-auto mr-auto mb-10 sm:mb-16 flex gap-2 items-center justify-center">
          Telios AI
        </h1> */}
        <div></div>
        <div className="mt-2 sm:mt-[5vh] mb-10 sm:mb-20 w-full">
          <Listbox value={selected} onChange={switchAgent}>
            {({ open }) => (
              <div className="relative w-full max-w-lg m-auto">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/50 dark:bg-zinc-800/50 py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-zinc-500 focus:outline-none focus:ring-2 focus:ring-purple-400 sm:text-sm sm:leading-6">
                  <Listbox.Label className="block text-xs leading-6 text-gray-800 dark:text-gray-400">
                    Agent
                  </Listbox.Label>
                  <span className="block truncate font-semibold dark:text-white text-gray-900">
                    {selected?.display_name}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-800 py-1 text-xs shadow-lg ring-1 ring-gray-300 dark:ring-zinc-500 focus:outline-none sm:text-sm">
                    {data.map((agent) => (
                      <Listbox.Option
                        key={agent.id}
                        className={({ active }) =>
                          clsx(
                            active ? "dark:bg-zinc-700 bg-zinc-100" : "",
                            "text-gray-900 dark:text-white relative cursor-pointer select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={agent}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={clsx(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {agent.display_name}
                            </span>

                            {selected ? (
                              <span className="dark:text-white text-gray-900 absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon
                                  className="h-6 w-6 font-bold"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
        <div className="md:flex items-start text-center gap-3.5">
          <div className="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1">
            <h2 className="flex gap-3 items-center m-auto text-lg font-normal md:flex-col md:gap-2">
              <SunIcon className="h-7 w-7" />
              Examples
            </h2>
            <ul className="flex flex-col gap-3.5 w-full sm:max-w-md m-auto">
              <button
                onClick={() => setPrompt("What is the Coil Check Procedure?")}
                className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md hover:bg-gray-200 dark:hover:bg-zinc-700"
              >
                "What is the Coil Check Procedure?" →
              </button>
              <button
                onClick={() =>
                  setPrompt("Can the Pressure Gauge be Calibrated?")
                }
                className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md hover:bg-gray-200 dark:hover:bg-zinc-700"
              >
                "Can the Pressure Gauge be Calibrated?" →
              </button>
              <button
                onClick={() => setPrompt("Filter pump motor is not working.")}
                className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md hover:bg-gray-200 dark:hover:bg-zinc-700"
              >
                "Filter pump motor is not working." →
              </button>
            </ul>
          </div>
          <div className="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1">
            <h2 className="flex gap-3 items-center m-auto text-lg font-normal md:flex-col md:gap-2">
              <BoltIcon className="w-7 h-7" />
              Capabilities
            </h2>
            <ul className="flex flex-col gap-3.5 w-full sm:max-w-md m-auto">
              <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                Remembers what user said earlier in the conversation
              </li>
              <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                Allows user to provide follow-up corrections
              </li>
              <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                Learns over time when given new information or how a problem was
                solved.
              </li>
            </ul>
          </div>
          <div className="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1">
            <h2 className="flex gap-3 items-center m-auto text-lg font-normal md:flex-col md:gap-2">
              <ExclamationTriangleIcon className="w-7 h-7" />
              Limitations
            </h2>
            <ul className="flex flex-col gap-3.5 w-full sm:max-w-md m-auto">
              <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                Needs human input to acquire knowledge beyond what is in the
                technical documents.
              </li>
              <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                May occasionally not have the answer to your question.
              </li>
              <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                Knowledge limited to the few manuals it was trained on.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroPrompt;
