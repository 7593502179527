// This code came from https://github.com/ProtonMail/proton-shared/blob/master/lib/helpers/function.ts
// Which is under an open source license
import CONSTANTS from "./constants";

const { USER_ROLE } = CONSTANTS;

export const noop = () => undefined;

export const debounce = (func, wait, isImmediate) => {
  let timeoutId;

  function debouncedFunction(...args) {
    const later = () => {
      timeoutId = undefined;
      if (!isImmediate) {
        func.apply(this, args);
      }
    };

    const shouldCallNow = isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(later, wait);

    if (shouldCallNow) {
      func.apply(this, args);
    }
  }

  debouncedFunction.abort = () => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  return debouncedFunction;
};

export const throttle = (func, ms = 50, context = window) => {
  let wait = false;

  return (...args) => {
    const later = () => {
      func.apply(context, args);
    };

    if (!wait) {
      later();
      wait = true;
      setTimeout(() => {
        wait = false;
      }, ms);
    }
  };
};

export const getAdminRole = (roles) =>
  roles.includes(USER_ROLE.ORG_ADMIN) || roles.includes(USER_ROLE.SUPER_ADMIN);

export const getSuperAdminRole = (roles) =>
  roles.includes(USER_ROLE.SUPER_ADMIN);
