import React from "react";
import ReactMarkdown from "react-markdown";
import { Prose } from "./Prose";
import remarkGfm from "remark-gfm";

const ResponsePrompt = (props) => {
  const { markdown, error } = props;

  const renderContent = () => {
    if (markdown) {
      return (
        <Prose as="article" className="leading-3">
          <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
        </Prose>
      );
    } else if (error) {
      return (
        <span className="text-red-500">
          An error occurred - "{error.message}". Refresh the page and try again
          later.
        </span>
      );
    } else {
      return (
        <div className="relative flex top-[10px]">
          <div className="flex items-center justify-center space-x-2">
            <div className="w-2 h-2 rounded-full animate-pulse bg-zinc-900 dark:bg-gray-100"></div>
            <div className="w-2 h-2 rounded-full animate-pulse bg-zinc-900 dark:bg-gray-100"></div>
            <div className="w-2 h-2 rounded-full animate-pulse bg-zinc-900 dark:bg-gray-100"></div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full border-b border-black/10 dark:border-[#444654]/20 text-zinc-900 dark:text-gray-100 group bg-zinc-200/20 dark:bg-[#444654]/40">
      <div className="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
        <div className="w-[30px] flex flex-col relative items-end">
          <div className="relative h-[30px] w-[30px] p-1 rounded-md text-white bg-purple-700 flex items-center justify-center">
            <span>B</span>
          </div>
        </div>
        <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
          <div className="flex flex-grow flex-col gap-3">
            <div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">
              <div className="w-full break-words">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsePrompt;
