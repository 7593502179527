import { Dialog, Listbox, Tab, Transition } from "@headlessui/react";
import { ArrowLeftIcon, FingerPrintIcon } from "@heroicons/react/20/solid";
import {
  CheckIcon,
  ChevronDownIcon,
  ClipboardIcon,
  CodeBracketIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  Square3Stack3DIcon,
  SquaresPlusIcon,
  SunIcon,
  SwatchIcon,
  TrashIcon,
  WindowIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";

//internal
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button } from "../../../components/Button";
import { SystemInformation } from "../../../components/Cards";
import { Input, SearchCombobox, Textarea } from "../../../components/Input";
import Loader from "../../../components/Loader";
import { DeletionModal } from "../../../components/Modals";
import { useFetchContext } from "../../../contextProviders/FetchProvider";
import { useNotificationContext } from "../../../contextProviders/NotificationProvider";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import useForm from "../../../utils/hooks/useForms";
// import InstitutionOverviewTab from "./components/InstitutionOverviewTab";
// import InstitutionOrganisationsTab from "./components/InstitutionOrganisationsTab";
import { useGlobal } from "../../../contextProviders/GlobalProvider";
import { CONSTANTS } from "../../../utils/helpers";
import { tabs } from "../../../utils/helpers/tabs";

const { LLL_MODELS } = CONSTANTS;

export const SummaryTab = (props) => {
  const { data: details } = props;
  const { fetchWrapper, fetcherFn } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const { hasAdminRole } = useGlobal();

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const previousModel =
    details?.llm?.model || details?.model || LLL_MODELS[0].name;
  const [selectedModel, setSelectedModel] = useState(previousModel);

  const queryClient = useQueryClient();

  const { mutate, isLoading: isUpdateLoading } = useMutation({
    mutationFn: ({ id, updates }) => {
      return fetchWrapper(`agent/${id}`, "PATCH", {}, updates);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [`agent/${data.id}`],
      });
      const params = {
        success: true,
        msg: "Agent successfully updated!",
      };
      emitNotification(params);
    },
  });

  const {
    handleSubmit,
    handleChange,
    manualChange,
    resetForm,
    isDirty,
    runValidations,
    data,
    errors,
  } = useForm({
    initialValues: {
      name: details.name,
      display_name: details.display_name,
      description: details.description,
      systemMsg: details.systemMsg,
      prompt: details.prompt,
      temperature: details?.llm?.temperature || details?.temperature || 0,
      // top_k: details.top_k,
      logo_url: "",
    },
    validationDebounce: 500,
    validations: {
      name: {
        required: {
          value: true,
          message: "Name required.",
        },
        pattern: {
          value: /^[a-z_0-9]+$/gu,
          message: "Invalid entry. The name must be all be snake_case.",
        },
      },
      display_name: {
        required: {
          value: true,
          message: "Display Name required.",
        },
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9/'/-/.]+([\p{L}\p{M}\p{Zs}0-9/'\-/./&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      description: {
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9/'/-/.]+([\p{L}\p{M}\p{Zs}0-9/'\-/./&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      systemMsg: {
        required: {
          value: false
        },
      },
      prompt: {
        required: {
          value: false,
          message: "Prompt required.",
        },
        // pattern: {
        //   value:
        //     /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9/'/-/.]+([\p{L}\p{M}\p{Zs}0-9/'\-/./&]+)*/gu,
        //   message:
        //     "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        // },
      },
      temperature: {
        required: {
          value: true,
          message: "Temperature required.",
        },
        pattern: {
          // value: /^(?!0+$)[1-9]\d*$/gu,
          // message: "Invalid entry. Must be a number greater than 0",
          value: /^(?!-1+$)[0-9]\d*$/gu,
          message: "Invalid entry. Must be a number equal or greater than 0",
        },
      },
      // top_k: {
      //   required: {
      //     value: true,
      //     message: "Top K required.",
      //   },
      //   pattern: {
      //     value: /^(?!0+$)[1-9]\d*$/gu,
      //     message: "Invalid entry. Must be a number greater than 0",
      //   },
      // },
    },
    onSubmit: async ({
      display_name,
      description,
      systemMsg,
      prompt,
      temperature,
      // top_k,
      logo_url,
    }) => {
      const payload = {
        description,
        display_name,
        systemMsg,
        prompt,
        tools: {
          redact: true,
        },
        // top_k,
        logo_url,
        llm: {
          name: "openai",
          model: selectedModel,
          temperature,
        },
      };
      mutate({ id: details.id, updates: payload });
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className=" border border-zinc-900/15 dark:border-white/10 divide-y divide-zinc-900/10 dark:divide-white/10 sm:rounded-lg bg-white/40 dark:bg-white/10">
          <div className="md:grid md:grid-cols-5 md:gap-6 px-4 pt-5 sm:pt-9 pb-4 sm:px-9">
            <div className="md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                Details
              </h3>
              <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                Basic information about the agent.
              </p>
            </div>
            <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
              <div className="grid grid-cols-2 gap-4">
                <Input
                  id="display_name"
                  name="diplay_name"
                  type="text"
                  required
                  label="Display Name"
                  placeholder="Chatbot"
                  className="col-span-1"
                  error={errors.display_name}
                  value={data.display_name}
                  prepend={<WindowIcon className="h-4 w-4 text-zinc-500" />}
                  onChange={handleChange("display_name", true)}
                  disabled={!hasAdminRole}
                />
                <Input
                  id="name"
                  name="name"
                  type="text"
                  required
                  label="Name"
                  placeholder="chatbot"
                  className="col-span-1"
                  error={errors.name}
                  value={data.name}
                  disabled
                  prepend={
                    <CodeBracketIcon className="h-4 w-4 text-zinc-500" />
                  }
                  onChange={handleChange("name", true)}
                />
              </div>
              <div>
                <Textarea
                  id="description"
                  name="description"
                  label="Description"
                  rows={2}
                  placeholder="A description about Chatbot..."
                  error={errors.description}
                  value={data.description}
                  onChange={handleChange("description", true)}
                  disabled={!hasAdminRole}
                />
                <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
                  Brief description of this agent and its uses.
                </p>
              </div>
              <div>
                <Textarea
                  id="systemMsg"
                  name="systemMsg"
                  label="System Message"
                  placeholder=""
                  error={errors.systemMsg}
                  value={data.systemMsg}
                  onChange={handleChange("systemMsg", true)}
                  disabled={!hasAdminRole}
                />
                {/* <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
                  The system message.
                </p> */}
              </div>
              <div>
                <Textarea
                  id="prompt"
                  name="prompt"
                  label="Model Prompt"
                  placeholder="You are a virtual chatbot that answers general questions about things."
                  error={errors.prompt}
                  value={data.prompt}
                  onChange={handleChange("prompt", false)}
                  disabled={!hasAdminRole}
                />
                <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
                  The prompt used to setup the AI's response.
                </p>
              </div>
              <div className="grid grid-cols-12 gap-4">
                {hasAdminRole ? (
                  <div className="col-span-6">
                    <label
                      htmlFor={"model"}
                      className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
                    >
                      Model
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm relative dark:shadow-md ">
                      <Listbox
                        value={selectedModel}
                        onChange={setSelectedModel}
                      >
                        {({ open }) => (
                          <div className="relative w-full max-w-lg m-auto">
                            <Listbox.Button className="relative rounded w-full h-10 cursor-default rounded-r-md flex bg-white/50 dark:bg-zinc-800/50 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-zinc-500 focus:outline-none  dark:focus:outline-none focus:ring-2 dark:focus:ring-2 focus:ring-purple-400 dark:focus:ring-purple-400 sm:text-sm sm:leading-6">
                              <span className="inline-flex h-[inherit] items-center rounded-l border border-r-0 border-zinc-900/15 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-3 dark:text-zinc-400 text-zinc-500 sm:text-sm">
                                <SwatchIcon className="h-4 w-4 text-zinc-500" />
                              </span>
                              <span className="block truncate ml-4 my-auto dark:text-white text-slate-700">
                                {selectedModel?.name || selectedModel}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                  className="h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[18rem] overflow-auto rounded-md bg-white dark:bg-zinc-800 py-1 text-xs shadow-lg ring-1 ring-gray-300 dark:ring-zinc-500 focus:outline-none sm:text-sm bottom-10">
                                {LLL_MODELS.map((model) => (
                                  <Listbox.Option
                                    key={model.name}
                                    className={({ active }) =>
                                      clsx(
                                        active
                                          ? "dark:bg-zinc-700 bg-zinc-100"
                                          : "",
                                        "text-gray-900 dark:text-white relative cursor-pointer select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={model.name}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={clsx(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {model.name}
                                        </span>

                                        {selected ? (
                                          <span className="dark:text-white text-gray-900 absolute inset-y-0 right-0 flex items-center pr-4">
                                            <CheckIcon
                                              className="h-6 w-6 font-bold"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        )}
                      </Listbox>
                      <div className="absolute -bottom-6 left-3 flex items-center justify-start text-xs">
                        <div className="text-red-600">{errors.model}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Input
                    id="model"
                    className="col-span-6"
                    name="model"
                    type="text"
                    required
                    label="Model"
                    placeholder="text-davinci-003"
                    error={errors.model}
                    value={selectedModel}
                    prepend={<SwatchIcon className="h-4 w-4 text-zinc-500" />}
                    onChange={handleChange("model", true)}
                    disabled={!hasAdminRole}
                  />
                )}
                <Input
                  id="temperature"
                  className="col-span-3"
                  name="temperature"
                  type="text"
                  required
                  label="Temperature"
                  placeholder="0"
                  error={errors.temperature}
                  value={data.temperature}
                  prepend={<SunIcon className="h-4 w-4 text-zinc-500" />}
                  onChange={handleChange("temperature", true)}
                  disabled={!hasAdminRole}
                />
                {/* <Input
                  id="top_k"
                  className="col-span-3"
                  name="top_k"
                  type="text"
                  required
                  label="Top K"
                  placeholder="10"
                  error={errors.top_k}
                  value={data.top_k}
                  prepend={
                    <Square3Stack3DIcon className="h-4 w-4 text-zinc-500" />
                  }
                  onChange={handleChange("top_k", true)}
                  disabled={!hasAdminRole}
                /> */}
              </div>
              {/* <Input
                id="api_key"
                name="api_key"
                type="text"
                required
                label="Api Key"
                value={data.api_key}
                prepend={<KeyIcon className="h-4 w-4 text-zinc-500" />}
                onChange={handleChange("name", true)}
              /> */}
            </div>
          </div>
          {hasAdminRole && (
            <div className="mt-6 flex flex-row justify-end space-x-3 px-4 py-5 sm:px-9">
              <Button
                variant="secondary"
                onClick={() => resetForm()}
                type="button"
                disabled={!isDirty}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                // disabled={!isDirty}
                type="submit"
                loading={isUpdateLoading}
              >
                Update
              </Button>
            </div>
          )}
        </div>
      </form>
      <SystemInformation
        // createdAt={details.createdAt}
        createdBy={details.createdBy}
        // updatedAt={details.updatedAt}
        updatedBy={details.updatedBy}
        className="mt-4"
      />
      {hasAdminRole && (
        <>
          <div className="relative mt-8 mb-4">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-zinc-900/15 dark:border-white/10" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white dark:bg-zinc-900 px-3 text-lg font-medium uppercase text-zinc-900 dark:text-zinc-50">
                Danger Zone
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-between rounded-md bg-red-100 border border-red-200 dark:border-red-300 dark:bg-red-200 px-6 py-6">
            <div className="space-y-1 text-sm">
              <span className="font-semibold text-red-700 text-base">
                Delete Agent
              </span>
              <p className="text-red-600">
                This will remove the agent and all its associated data.
              </p>
            </div>
            <div className="self-center">
              <Button
                variant="danger"
                onClick={() => setIsDeletionModalOpen(true)}
              >
                Delete
              </Button>
            </div>
          </div>
        </>
      )}
      <DeletionModal
        setIsOpen={setIsDeletionModalOpen}
        isOpen={isDeletionModalOpen}
        deleteApiUrl={`agent/${details.id}`}
        entityLabel={`Agent - ${details.display_name}`}
      >
        <p className="text-sm text-zinc-600 dark:text-zinc-400">
          Are you sure you want to delete this Agent? It will be lost forever.
        </p>
      </DeletionModal>
    </>
  );
};

export const EntryTab = (props) => {
  const { data: details } = props;
  const { fetchWrapper, fetcherFn } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);

  const addToNexus = async (metadata, context, sources) => {
    setIsLoading(true);
    try {
      const payload = {
        organization: details.org_id,
        namespace: details.id,
        context,
        metadata,
        sources:
          sources.length > 0
            ? sources.split(",").map((value) => value.trim())
            : [],
      };
      await fetchWrapper("nexus", "POST", {}, payload);
      const params = {
        success: true,
        msg: "Context successfully added!",
      };
      emitNotification(params);
      setIsLoading(false);
      return params;
    } catch (err) {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
      setIsLoading(false);
      return params;
    }
  };

  const { handleSubmit, handleChange, resetForm, isDirty, data, errors } =
    useForm({
      initialValues: {
        context: "",
        metadata: "",
        sources: "",
      },
      validationDebounce: 500,
      validations: {
        context: {
          required: {
            value: true,
            message: "Context data required.",
          },
        },
      },
      onSubmit: async ({ context, metadata, sources }) => {
        const results = await addToNexus(metadata, context, sources);
        if (results.success) {
          resetForm({
            context: "",
            metadata,
            sources: "",
          });
        }
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className=" border border-zinc-900/15 dark:border-white/10 divide-y divide-zinc-900/10 dark:divide-white/10 sm:rounded-lg bg-white/40 dark:bg-white/10">
        <div className="divide-zinc-900/10 dark:divide-white/10 divide-y px-4 sm:px-9 space-y-10">
          <div className="md:grid md:grid-cols-5 md:gap-6  pt-5 sm:pt-9">
            <div className="md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                Metadata
              </h3>
              <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                Data that will be added on top of each context block upon
                insert.
              </p>
            </div>
            <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
              <Textarea
                id="metadata"
                name="metadata"
                label="metadata"
                rows={4}
                placeholder="Metadata will persist after each insert."
                error={errors.metadata}
                value={data.metadata}
                onChange={handleChange("metadata", true)}
              />
            </div>
          </div>
          <div className="md:grid md:grid-cols-5 md:gap-6 pt-5 sm:pt-9 pb-4">
            <div className="md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                Context
              </h3>
              <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                Data that will be vectorized and used to train the model.
              </p>
            </div>
            <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
              <Textarea
                id="context"
                name="context"
                label="context"
                rows={20}
                placeholder="Enter context data here..."
                error={errors.context}
                value={data.context}
                onChange={handleChange("context", true)}
              />
            </div>
          </div>
          <div className="md:grid md:grid-cols-5 md:gap-6 pt-5 sm:pt-9 pb-4">
            <div className="md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                Sources
              </h3>
              <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                List all the sources that this context is stemmed from.
              </p>
            </div>
            <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
              <Textarea
                id="sources"
                name="sources"
                label="sources"
                rows={2}
                placeholder="manual.pdf, mycompanywebsite.com/product, ..."
                error={errors.sources}
                value={data.sources}
                onChange={handleChange("sources", true)}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-row justify-end space-x-3 px-4 py-5 sm:px-9">
          <Button
            variant="secondary"
            onClick={() => resetForm()}
            type="button"
            disabled={!isDirty}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!isDirty}
            type="submit"
            loading={isLoading}
          >
            Insert
          </Button>
        </div>
      </div>
    </form>
  );
};

const CopyButton = ({ code }) => {
  let [copyCount, setCopyCount] = useState(0);
  let copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      let timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <button
      type="button"
      className={clsx(
        "group/button absolute top-3.5 right-4 overflow-hidden rounded-full py-1 pl-2 pr-3 text-2xs font-medium opacity-0 backdrop-blur transition focus:opacity-100 group-hover:opacity-100",
        copied
          ? "bg-emerald-400/10 ring-1 ring-inset ring-emerald-400/20"
          : "bg-white/5 hover:bg-white/7.5 dark:bg-white/2.5 dark:hover:bg-white/5"
      )}
      onClick={() => {
        window.navigator.clipboard.writeText(code).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
    >
      <span
        aria-hidden={copied}
        className={clsx(
          "pointer-events-none flex items-center gap-0.5 text-zinc-400 transition duration-300",
          copied && "-translate-y-1.5 opacity-0"
        )}
      >
        <ClipboardIcon className="h-5 w-5 fill-zinc-500/20 stroke-zinc-500 transition-colors group-hover/button:stroke-zinc-400" />
        Copy
      </span>
      <span
        aria-hidden={!copied}
        className={clsx(
          "pointer-events-none absolute inset-0 flex items-center justify-center text-emerald-400 transition duration-300",
          !copied && "translate-y-1.5 opacity-0"
        )}
      >
        Copied!
      </span>
    </button>
  );
};

export const SearchTab = (props) => {
  const { data: details } = props;
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const { fetcherFn } = useFetchContext();
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const {
    data: results,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["nexus", debouncedQuery],
    () =>
      fetcherFn({
        queryKey: [`nexus`],
        params: {
          organization: details.org_id,
          query: debouncedQuery,
          namespace: details.id,
          limit: 10,
        },
      }),
    {
      staleTime: 0,
      enabled: Boolean(debouncedQuery && debouncedQuery.length > 0),
    }
  );

  const describeResults = (array) => {
    const count = array?.length ?? 0;

    if (isLoading) {
      return (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 dark:text-white text-zinc-900"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      );
    }

    if (count === 0) {
      return "No results found.";
    } else if (count === 1) {
      return "Showing one result.";
    } else {
      return `Showing ${count} results.`;
    }
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleDelete = (id) => {
    setSelectedId(id);
    setIsDeletionModalOpen(true);
  };

  return (
    <div className="h-full w-full flex justify-center">
      <div className="relative z-10 text-gray-800 w-full md:h-full md:flex md:flex-col px-2 dark:text-gray-100 mx-auto">
        {/* <h1 className="text-3xl font-semibold text-left mb-2 sm:mb-3 flex gap-2 items-start justify-start">
          Context Search
        </h1> */}
        <div className="md:flex text-left flex flex-col items-start h-full overflow-hidden mb-5">
          <p className="flex mb-6">
            The search function is meant to look through any context data that
            may have been uploaded in order to update or delete said context.
            Any edit or delete actions are permanent and cannot be reversed.
          </p>
          <div className="w-full flex flex-col items-center justify-between p-4 border rounded-lg shadow-sm dark:bg-white/10 bg-zinc-200/50 dark:bg-zinc-800 border-zinc-900/15 dark:border-white/10 sm:flex-row">
            <div className="flex-shrink-0 w-full sm:w-auto sm:flex">
              <div className="relative flex-shrink-0 w-full mb-4 sm:mb-0 sm:mr-4 sm:w-64 lg:w-96">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon className="h-5 w-5" />
                </div>
                <label htmlFor="search" className="hidden">
                  Search block sections:
                </label>
                <input
                  id="search"
                  type="text"
                  className="bg-white border border-zinc-900/15 dark:border-white/10 text-gray-800 text-sm rounded-lg focus:ring-purple-400 focus:border-purple-400 block w-full pl-10 p-2.5 py-2 dark:bg-white/2.5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-400 dark:focus:border-purple-400"
                  placeholder="Search Data Context"
                  onChange={handleInputChange}
                  value={query}
                />
              </div>
            </div>
            <div className="hidden text-sm text-gray-600 dark:text-gray-400 sm:block">
              {query.length > 0 && !isError && describeResults(results)}
              {query.length > 0 && isError && (
                <span className="text-red-500">{error.message}</span>
              )}
            </div>
          </div>
          <div className="relative flex-grow overflow-hidden">
            <div className="absolute z-10 h-6 w-full md:bg-vert-light-gradient-inverse bg-white dark:bg-zinc-800 md:!bg-transparent dark:md:bg-vert-dark-gradient-inverse" />
            <div className="absolute z-10 bottom-0 h-6 w-full md:bg-vert-light-gradient bg-white dark:bg-zinc-800 md:!bg-transparent dark:md:bg-vert-dark-gradient" />
            <div className="h-full overflow-y-auto">
              <ul role="list" className="space-y-4 pt-6 mb-6">
                {results &&
                  results.results &&
                  results.results.map((obj) => (
                    <li
                      key={obj.id}
                      className="not-prose overflow-hidden rounded-2xl bg-zinc-800 shadow-md dark:ring-1 dark:ring-white/10"
                    >
                      <div className="flex min-h-[calc(theme(spacing.12)+1px)] flex-wrap items-start gap-x-4 border-b border-zinc-700 bg-white/10 px-4 dark:border-zinc-800 dark:bg-transparent">
                        <h3 className="mr-auto pt-3 text-xs font-semibold text-purple-300">
                          {obj.id}
                        </h3>
                        <div className="-mb-px pt-3.5 flex gap-3 text-xs font-medium flex-row text-zinc-500 dark:text-zinc-600">
                          <PencilSquareIcon className="h-4 w-4 cursor-pointer  hover:text-zinc-300" />
                          <TrashIcon
                            className="h-4 w-4 cursor-pointer  hover:text-zinc-300"
                            onClick={() => handleDelete(obj.id)}
                          />
                        </div>
                      </div>
                      <div className="group dark:bg-white/5">
                        <div className="relative">
                          <pre className="overflow-x-auto p-4 text-xs text-white whitespace-pre-line">
                            {obj.text}
                          </pre>
                          <CopyButton code={obj.text} />
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {/* <Button type="button" search="left" {...buttonProps}>Search Context Data</Button> */}
        </div>
      </div>
      <DeletionModal
        setIsOpen={setIsDeletionModalOpen}
        isOpen={isDeletionModalOpen}
        deleteApiUrl={`nexus/${selectedId}`}
        entityLabel={selectedId}
      >
        <p className="text-sm text-zinc-600 dark:text-zinc-400">
          Are you sure you want to delete this context block? It will be lost
          forever.
        </p>
      </DeletionModal>
    </div>
  );
};

export const Modal = (props) => {
  const { isOpen, setIsOpen, agentId } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [integration, setIntegration] = useState({
    id: "google_drive",
    name: "Google Drive",
  });
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ payload }) => {
      return fetchWrapper(`agent/${agentId}/integration`, "POST", {}, payload);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [`agent/${agentId}/integration`],
      });
      const params = {
        success: true,
        msg: "Integration successfully linked!",
      };
      emitNotification(params);
    },
    onSettled: () => {
      closeModal();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (integration && integration.id === "google_drive") {
      const payload = {
        type: "google_drive",
        allowed_file_types: [],
        exclude: [],
        folders: [],
        inherit_user_access: true,
      };
      mutate({ payload });
    } else {
      setError("Select valid integration.");
    }
  };

  const onIntegrationChange = (e) => {
    setError("");
    setIntegration({ ...e.target.value });
  };

  const closeModal = () => {
    setIntegration({ id: "google_drive", name: "Google Drive" });
    setIsOpen(false);
  };

  const existingIntegrations = [{ id: "google_drive", name: "Google Drive" }];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 pt-40 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-zinc-800 p-8 text-left align-middle shadow-xl transition-all">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-zinc-800 text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50"
                >
                  Link an Integration
                </Dialog.Title>
                <div className="my-3">
                  <p className="text-sm dark:text-zinc-400 text-zinc-500">
                    Select one of the integration setup for your organization
                    and link it to this agent.
                  </p>
                </div>
                <form className="" onSubmit={handleSubmit}>
                  <div className="relative mt-4 mb-8">
                    <SearchCombobox
                      data={existingIntegrations}
                      id="member_role"
                      label=""
                      value={integration}
                      error={error}
                      onChange={onIntegrationChange}
                      prepend={
                        <SquaresPlusIcon className="h-4 w-4 text-zinc-500" />
                      }
                    />
                  </div>

                  <div className="mt-5 sm:mt-6 sm:flex sm:justify-end">
                    <div className="sm:flex sm:flex-row-reverse gap-4">
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Link
                      </Button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-full border border-zinc-300 bg-white dark:bg-white/10 dark:hover:bg-white/5 dark:border-white/10 px-4 py-1 text-base font-medium text-zinc-400 shadow-sm hover:bg-zinc-100  hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-300 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const IntegrationRow = (props) => {
  const {
    image = "",
    id = "",
    name = "",
    status = false,
    agentName = "",
  } = props;
  const navigate = useNavigate();
  const onConfigure = () => {
    navigate(`integration/${id}`, { state: { origin: agentName } });
  };
  return (
    <tr className="group">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div className="h-12 w-12 flex-shrink-0">
            <div
              className="relative z-10 h-12 w-12 bg-no-repeat bg-center"
              style={{ backgroundImage: `url("${image}")` }}
              alt=""
            />
          </div>
          <div className="ml-4">
            {/* group-hover:text-purple-500 dark:group-hover:text-purple-500 */}
            <div className="font-medium dark:text-zinc-300">{name}</div>
            <div className="text-gray-500">{id}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="flex flex-row items-center space-x-2">
          <span className="relative flex h-2 w-2">
            <span
              className={clsx(
                "relative inline-flex rounded-full h-2 w-2",
                status ? "bg-emerald-500" : "bg-zinc-400"
              )}
            ></span>
          </span>
          <span className="text-gray-500">
            {status ? "Enabled" : "Disabled"}
          </span>
        </div>
      </td>
      <td>
        <div className="flex flex-row-reverse">
          <Button variant="outline" onClick={onConfigure}>
            Configure
          </Button>
        </div>
      </td>
    </tr>
  );
};

export const IntegrationsTab = (props) => {
  const { data: agent } = props;
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const { fetcherFn } = useFetchContext();
  const { data: integrations, isLoading } = useQuery(
    [`agent/${agent.id}/integration`],
    fetcherFn,
    {
      staleTime: 60000,
      keepPreviousData: true,
    }
  );

  const googleDrive =
    integrations && integrations.find((int) => int.id === "google_drive");

  if (isLoading) {
    return (
      <div className="mt-20 flex w-full justify-center">
        <Loader className="h-10 w-10 text-zinc-400" />
      </div>
    );
  }

  return (
    <>
      <div className="px-4 sm:px-6">
        <div className="sm:flex sm:items-center -mx-2 sm:-mx-4">
          <div className=" sm:flex-auto">
            <h1 className="text-lg font-semibold leading-6 text-zinc-900 dark:text-white">
              Integrations
            </h1>
            <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
              Enable and configure integrations you want to connect to this
              agent.
            </p>
          </div>
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button
              type="button"
              onClick={() => setIsRegistrationModalOpen(true)}
              plus="left"
            >
              Link Integration
            </Button>
          </div> */}
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-zinc-900/15 dark:divide-white/10">
                <thead className="border-b border-zinc-900/15 dark:border-white/10">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-white sm:pl-0"
                    >
                      Integration
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                    >
                      Status
                    </th>
                    <th>
                      <span className="sr-only">Configure</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-zinc-900/15 dark:divide-white/10">
                  <IntegrationRow
                    image="/gdrive.png"
                    name="Google Drive"
                    id="google_drive"
                    status={!!googleDrive?.enabled}
                    agentName={agent.display_name}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isRegistrationModalOpen}
        setIsOpen={setIsRegistrationModalOpen}
        agentId={agent.id}
      />
    </>
  );
};

export const APITab = (props) => {
  const { data: agent } = props;

  const codeString = `const axios = require("axios");

const options = { 
  method: "POST",
  url: "http://api.telios.io/agent/${agent.id}/completion",
  headers: { "X-API-KEY": "org_api_key" },
  data: {
    input: "What can you tell me about your capabilities?"
  }
};

axios(options)
  .then(response => {
    console.log(response.data.text);
    console.log(response.data.sources);
  })
  .catch(error => {
    console.log(error);
  });`;

  return (
    <>
      <div className="px-4 sm:px-6">
        <div className="sm:flex sm:items-center -mx-2 sm:-mx-4">
          <div className=" sm:flex-auto">
            <h1 className="text-lg font-semibold leading-6 text-zinc-900 dark:text-white">
              API Access
            </h1>
            <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
              You can use your organization's API key to communicate with this
              agent for machine-to-machine applications.
            </p>

            <div className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
              <SyntaxHighlighter
                language="javascript"
                style={stackoverflowDark}
              >
                {codeString}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const tabs = [
//   { id: "summary", name: "Summary", component: SummaryTab },
//   { id: "data_entry", name: "Data Entry", component: EntryTab },
//   { id: "context_search", name: "Context Search", component: SearchTab },
//   { id: "integrations", name: "Integrations", component: IntegrationsTab },
//   { id: "API", name: "API", component: APITab },
//   // {name:'Members', component: null },
// ];

export const AgentDetails = () => {
  const { agentId } = useParams();
  const { fetcherFn } = useFetchContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data: agent, isLoading } = useQuery([`agent/${agentId}`], fetcherFn, {
    enabled: !!agentId,
  });

  if (isLoading) {
    return (
      <div className="mt-20 flex w-full justify-center">
        <Loader className="h-10 w-10 text-zinc-400" />
      </div>
    );
  }

  return (
    <main className="mx-auto flex w-full min-w-[405px] max-w-7xl flex-col p-10">
      <button
        onClick={() => navigate(-1)}
        className="flex flex-row items-center space-x-2 text-sm dark:text-zinc-400 text-zinc-500"
      >
        <ArrowLeftIcon className="h-4 w-4" />{" "}
        <span className="font-medium">
          {state && state.origin
            ? `Back to ${state.origin} `
            : `Back to Agents`}
        </span>
      </button>
      <div className="mt-4 ml-6 flex flex-col space-y-2">
        <h1 className="text-3xl font-medium text-zinc-900 dark:text-white">
          {agent?.display_name}
        </h1>
        <div className="flex flex-row items-center space-x-2 text-sm dark:text-zinc-400 text-zinc-500">
          <span>Agent ID :</span>
          <div className="relative flex rounded shadow-sm ">
            <span className="inline-flex items-center rounded-l border border-r-0 border-zinc-900/7.5 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-1 dark:text-zinc-400 text-zinc-500">
              <FingerPrintIcon className="h-3 w-3" />{" "}
            </span>
            <span className="rounded-r border border-zinc-900/15 dark:border-white/10 bg-zinc-100 dark:bg-white/10 py-0.5 px-1 text-xs text-zinc-400 dark:text-white">
              {agent.id}
            </span>
          </div>
        </div>
      </div>
      <Tab.Group className="mx-6 mt-8" as="nav">
        <Tab.List className="-mb-px flex flex-row space-x-6 border-b border-zinc-900/15 dark:border-white/10">
          {tabs.map((tab) => (
            <Tab className="" aria-label="Tabs" as={Fragment} key={tab.id}>
              {({ selected }) => (
                <div
                  key={tab.name}
                  className={clsx(
                    selected
                      ? "border-sky-500 text-sky-500"
                      : "border-transparent text-zinc-500 hover:border-zinc-300 hover:text-zinc-700",
                    "cursor-pointer whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                  )}
                >
                  {tab.name}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="py-8" as="div">
          {tabs.map((tab) => {
            return (
              <Tab.Panel key={`panel_${tab.id}`}>
                <tab.component data={agent} />
              </Tab.Panel>
            );
          })}
        </Tab.Panels>
      </Tab.Group>
    </main>
  );
};
