import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { GridPattern } from "../components/GridPattern";
import { useFetchContext } from "../contextProviders/FetchProvider";
import { useGlobal } from "../contextProviders/GlobalProvider";

const addedData = [
  {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icon: DocumentTextIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icon: DocumentTextIcon,
    pattern: {
      y: -6,
      squares: [
        [-1, 2],
        [1, 3],
      ],
    },
  },
  {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icon: DocumentTextIcon,
    pattern: {
      y: 32,
      squares: [
        [0, 2],
        [1, 4],
      ],
    },
  },
  {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icon: DocumentTextIcon,
    pattern: {
      y: 22,
      squares: [[0, 1]],
    },
  },
];

function AgentIcon({ icon: Icon }) {
  return (
    <div className="flex h-7 w-7 items-center justify-center rounded-full bg-zinc-900/5 ring-1 ring-zinc-900/25 backdrop-blur-[2px] transition duration-300 group-hover:bg-white/50 group-hover:ring-zinc-900/25 dark:bg-white/7.5 dark:ring-white/15 dark:group-hover:bg-purple-300/10 dark:group-hover:ring-purple-400">
      <Icon className="h-5 w-5 fill-zinc-700/10 stroke-zinc-700 transition-colors duration-300 group-hover:stroke-zinc-900 dark:fill-white/10 dark:stroke-zinc-400 dark:group-hover:fill-purple-300/10 dark:group-hover:stroke-purple-400" />
    </div>
  );
}

function AgentPattern({ mouseX, mouseY, ...gridProps }) {
  let maskImage = useMotionTemplate`radial-gradient(180px at ${mouseX}px ${mouseY}px, white, transparent)`;
  let style = { maskImage, WebkitMaskImage: maskImage };

  return (
    <div className="pointer-events-none">
      <div className="absolute inset-0 rounded-2xl transition duration-300 [mask-image:linear-gradient(white,transparent)] group-hover:opacity-50">
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5 dark:fill-white/1 dark:stroke-white/2.5"
          {...gridProps}
        />
      </div>
      <motion.div
        className="absolute inset-0 rounded-2xl bg-gradient-to-r from-[#ded7fe] to-[#b4eaff] opacity-0 transition duration-300 group-hover:opacity-100 dark:from-[#2d2744] dark:to-[#29434e]"
        style={style}
      />
      <motion.div
        className="absolute inset-0 rounded-2xl opacity-0 mix-blend-overlay transition duration-300 group-hover:opacity-100"
        style={style}
      >
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/50 stroke-black/70 dark:fill-white/2.5 dark:stroke-white/10"
          {...gridProps}
        />
      </motion.div>
    </div>
  );
}

function Agent({ agent }) {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect();
    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  }

  return (
    <div
      key={agent.to}
      onMouseMove={onMouseMove}
      className="group relative flex rounded-2xl bg-zinc-100 transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5"
    >
      <AgentPattern {...agent.pattern} mouseX={mouseX} mouseY={mouseY} />
      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
      <div className="relative rounded-2xl px-4 pt-16 pb-8">
        <AgentIcon icon={agent.icon} />
        <h3 className="mt-4 text-sm font-semibold leading-7 text-zinc-900 dark:text-white">
          <Link to={agent.id}>
            <span className="absolute inset-0 rounded-2xl" />
            {agent.display_name}
          </Link>
        </h3>
        <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
          {agent.description}
        </p>
      </div>
    </div>
  );
}

const AgentPicker = () => {
  const { fetcherFn } = useFetchContext();
  const { setGlobalAgent } = useGlobal();

  const { data, isLoading, error } = useQuery(["agent"], fetcherFn, {
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
  });

  const [agentData, setAgentData] = useState();

  useEffect(() => {
    if (!isLoading && data) {
      setAgentData(
        data.map((agent, index) => {
          return {
            ...agent,
            ...addedData[index % 4],
          };
        })
      );
      setGlobalAgent(data);
    }
  }, [data, isLoading]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <article className="prose dark:prose-invert max-w-3xl px-10 xl:px-0 m-auto pt-16">
        <h1>Welcome, let’s get started!</h1>
        <p className="lead dark:text-zinc-400 text-zinc-500">
          Choose a workflow from the options below to get started.
        </p>

        {/* {agentData && agentData.length > 0 && (
          <Link to={`/${agentData[0]?.id}`} className="no-underline">
            <div className="bg-[#6B72801F] px-10 pb-8 pt-5 dark:border-white/10">
              <div className="flex">
                <img src="/vector.png" alt="" width={30} height={30} />
                <span className="text-xl mx-5 mt-3 font-semibold text-black dark:text-white">
                  Chat
                </span>
              </div>
              <span className="text-black dark:text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                aliquet enim in tincidunt hendrerit.
              </span>
            </div>
          </Link>
        )}

        <hr className="m-0" /> */}

        {agentData && agentData.length > 0 && (
          <Link to={`/session`} className="no-underline">
            <div className="bg-[#6B72801F] px-10 pb-8 pt-5 dark:border-white/10">
              <div className="flex">
                <img src="/vector.png" alt="" width={30} height={30} />
                <span className="text-xl mx-5 mt-3 font-semibold text-black dark:text-white">
                  Business Analyzer
                </span>
              </div>
              <span className="text-black dark:text-gray-500">
                Writes business narratives and evaluates financial spreads based
                on lender's documents.
              </span>
            </div>
          </Link>
        )}
      </article>
    </>
  );
};

export default AgentPicker;
