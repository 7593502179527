import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  ChatBubbleLeftIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useFetchContext } from "../contextProviders/FetchProvider";
import { useGlobal } from "../contextProviders/GlobalProvider";
import { NotificationProvider } from "../contextProviders/NotificationProvider";
import { CONSTANTS } from "../utils/helpers";
import { Button } from "./Button";
import Loader from "./Loader";
import { DeletionModal } from "./Modals";

const { MODAL_TYPE } = CONSTANTS;

const sessionGroupsText = {
  today: "Today",
  previous7Days: "Previous 7 Days",
  previous30Days: "Previous 30 Days",
  olderSessions: "Older Sessions",
};

const Sessions = () => {
  const {
    globalModal,
    setGlobalModal,
    sessionsList,
    setSessionsList,
    isLoadingSessionList,
  } = useGlobal();
  const { fetchWrapper } = useFetchContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [filterValue, setFilterValue] = useState("");
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessionGroups, setSessionGroups] = useState(null);

  const { mutate: mutateUpdateSession } = useMutation({
    mutationFn: (payload) => {
      return fetchWrapper(
        `session/${selectedSession.id}`,
        "PATCH",
        {},
        payload
      );
    },
    onError: (err) => {
      return {
        success: false,
        msg: "Something went wrong!",
      };
    },
    onSuccess: () => {
      setGlobalModal({ ...globalModal, isOpen: false, titleValue: "" });
      queryClient.invalidateQueries();
    },
  });

  const generateGroupSession = (sessionsListData) => {
    const today = new Date();
    const sessionGroups = {
      today: [],
      previous7Days: [],
      previous30Days: [],
      olderSessions: [],
    };
    sessionsListData &&
      sessionsListData.forEach((sesh) => {
        const createdAt = new Date(sesh.createdAt);
        const timeDiff = today.getTime() - createdAt.getTime();
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (daysDiff === 0) {
          sessionGroups.today.push(sesh);
        } else if (daysDiff <= 7) {
          sessionGroups.previous7Days.push(sesh);
        } else if (daysDiff <= 30) {
          sessionGroups.previous30Days.push(sesh);
        } else {
          sessionGroups.olderSessions.push(sesh);
        }
      });
    return sessionGroups;
  };

  const { mutate: createNewSession } = useMutation({
    mutationFn: ({ type, payload }) => {
      return fetchWrapper(`session`, "POST", {}, payload);
    },
    onError: () => {
      return {
        success: false,
        msg: "Something went wrong!",
      };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries();
      setGlobalModal({ ...globalModal, isOpen: false, titleValue: "" });
      navigate(`/session/${data?.id}`);
      return {
        success: true,
        msg: "Session successfully created!",
      };
    },
  });

  const sessionCreate = (title) => {
    createNewSession({ type: "", payload: { title: title } });
  };

  const handleCreate = () => {
    setGlobalModal({
      action: sessionCreate,
      isOpen: true,
      onClose: () => {
        setGlobalModal({ ...globalModal, isOpen: false, titleValue: "" });
      },
      title: "New session",
      subtitle: "Enter a title for this session",
      titleValue: "",
      type: MODAL_TYPE.CREATE,
    });
  };

  const updateTitle = (title) => mutateUpdateSession({ title });

  const handleUpdate = (sesh) => {
    setSelectedSession(sesh);
    setGlobalModal({
      action: updateTitle,
      isOpen: true,
      onClose: () => {
        setGlobalModal({ ...globalModal, isOpen: false, titleValue: "" });
      },
      title: "Update session",
      subtitle: "Enter a title for this session",
      titleValue: sesh.title,
      type: MODAL_TYPE.UPDATE,
    });
  };

  const handleDelete = (sesh) => {
    setSelectedSession(sesh);
    setIsDeletionModalOpen(true);
  };

  useEffect(() => {
    if (filterValue) {
      const sessionsListFilter = sessionsList?.filter((sesh) =>
        sesh.title.toLowerCase().includes(filterValue.toLowerCase())
      );
      setSessionGroups(generateGroupSession(sessionsListFilter));
    } else {
      setSessionGroups(generateGroupSession(sessionsList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  useEffect(() => {
    if (sessionsList) {
      setSessionsList(
        sessionsList.sort((a, b) => `${b.createdAt}`.localeCompare(a.createdAt))
      );
      setSessionGroups(generateGroupSession(sessionsList));
    }
  }, [sessionsList]);

  return (
    <NotificationProvider>
      <nav>
        <Button
          plus="left"
          className="rounded-md py-2 justify-start w-full"
          variant="purple"
          onClick={handleCreate}
        >
          New Session
        </Button>
        <div className="mx-auto overflow-hidden rounded-lg mt-4 shadow-xl ring-1 ring-zinc-900/7.5 dark:bg-zinc-900 dark:ring-zinc-800 sm:max-w-xl">
          <div className="group relative flex h-12">
            {/* <SearchIcon className="pointer-events-none absolute left-3 top-0 h-full w-5 stroke-zinc-500" /> */}
            {/* <MagnifyingGlassIcon className="pointer-events-none absolute left-3 top-0 h-full w-5 stroke-zinc-500" /> */}
            <MagnifyingGlassIcon className="pointer-events-none absolute left-3 top-0 h-full w-5 stroke-stone-50" />
            <input
              //   ref={inputRef}
              className={clsx(
                "flex-auto appearance-none bg-transparent pl-10 outline-none placeholder:text-zinc-500 focus:w-full focus:flex-none text-white sm:text-sm [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden [&::-webkit-search-results-button]:hidden [&::-webkit-search-results-decoration]:hidden",
                "pr-4"
              )}
              placeholder="Search for name or keyword"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              onKeyDown={(event) => {
                if (
                  event.key === "Escape"
                  //   !autocompleteState.isOpen &&
                  //   autocompleteState.query === ''
                ) {
                  // In Safari, closing the dialog with the escape key can sometimes cause the scroll position to jump to the
                  // bottom of the page. This is a workaround for that until we can figure out a proper fix in Headless UI.
                  document.activeElement?.blur();
                  //   onClose()
                } else {
                  //   inputProps.onKeyDown(event)
                }
              }}
            />
          </div>
        </div>
        <ul role="list" className="pt-4">
          {sessionsList && sessionGroups && sessionsList.length
            ? Object.keys(sessionGroups).map((category, i) => (
                <div key={category}>
                  {sessionGroups[category].length > 0 && (
                    <span
                      className={`text-white block font-semibold ${
                        category != "today" && "mt-10"
                      }`}
                    >
                      {sessionGroupsText[category]}
                    </span>
                  )}
                  {sessionGroups[category].map((sesh) => (
                    <button
                      onClick={() => navigate(`/session/${sesh.id}`)}
                      key={sesh.id}
                      className={`rounded-md py-3 text-ellipsis whitespace-nowrap text-zinc-300 ${
                        location?.pathname.includes(sesh?.id) && "bg-white/10"
                      } my-2 hover:bg-zinc-300/10 w-full px-3 flex cursor-pointer`}
                      title={sesh.title}
                    >
                      <ChatBubbleLeftIcon className="mr-2 h-5 w-5" />
                      <div className="w-5/6 flex justify-between">
                        <div className="truncate">{sesh.title}</div>
                        <span className="flex w-8 ml-auto">
                          <PencilIcon
                            className="h-6 w-6 cursor-pointer hover:text-zinc-300 mr-2 hover:scale-150 ease-in duration-200"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdate(sesh);
                            }}
                          />
                          <TrashIcon
                            className="h-6 w-6 cursor-pointer hover:text-zinc-300 hover:scale-150 ease-in duration-200"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(sesh);
                            }}
                          />
                        </span>
                      </div>
                    </button>
                  ))}
                </div>
              ))
            : !isLoadingSessionList && (
                <div className="text-zinc-300">No Sessions found</div>
              )}
        </ul>
        {isLoadingSessionList && <Loader />}
        <DeletionModal
          setIsOpen={setIsDeletionModalOpen}
          isOpen={isDeletionModalOpen}
          deleteApiUrl={`session/${selectedSession?.id}`}
          entityLabel={selectedSession?.title}
          goBack
          navigateTo="/session"
        >
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Are you sure you want to delete this session? It will be lost
            forever.
          </p>
        </DeletionModal>
        <div className="bg-red-400"></div>
      </nav>
    </NotificationProvider>
  );
};

export default Sessions;
