import React, { createContext, useMemo, useState } from "react";

import Notification from "../components/Notification";

const NotificationContext = createContext(null);

const NotificationProvider = ({ children }) => {
  // Handle popup notification
  const [showNotification, setShowNotification] = useState(false);
  const [actionSucceeded, setActionSucceeded] = useState({
    success: true,
    msg: "",
  });

  const emitNotification = (payload = { success: true, msg: "" }) => {
    setActionSucceeded(payload);
    setShowNotification(true);
  };

  const value = useMemo(() => {
    return {
      emitNotification,
    };
  }, []);

  return (
    <NotificationContext.Provider value={value}>
      <>
        {children || ""}
        <Notification
          show={showNotification}
          setShow={setShowNotification}
          success={actionSucceeded?.success}
          successMsg={actionSucceeded?.msg}
          errorMsg={actionSucceeded?.msg}
        />
      </>
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider"
    );
  }
  return context;
};

export { NotificationProvider, useNotificationContext };
