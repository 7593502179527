import { ArrowLeftIcon, FingerPrintIcon } from "@heroicons/react/20/solid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import useDrivePicker from "react-google-drive-picker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Checkbox, Input } from "../../../components/Input";
import { useFetchContext } from "../../../contextProviders/FetchProvider";
import { useNotificationContext } from "../../../contextProviders/NotificationProvider";
import useForm from "../../../utils/hooks/useForms";

export const GoogleIntegrationDetails = (props) => {
  const { agentId, orgIntegrationData } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const [openPicker] = useDrivePicker();

  const { mutate, isLoading: isUpdateLoading } = useMutation({
    mutationFn: ({ updates }) => {
      return fetchWrapper(`agent/${agentId}/integration`, "POST", {}, updates);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [`agent/${agentId}/integration`],
      });
      const params = {
        success: true,
        msg: "Integration successfully updated!",
      };
      emitNotification(params);
    },
  });

  const {
    handleSubmit,
    manualChange,
    resetForm,
    isDirty,
    data,
    errors,
  } = useForm({
    initialValues: {
      allowed_file_types: [],
      exclude: [],
      folders: [],
    },
    validationDebounce: 500,
    validations: {},
    onSubmit: async ({ allowed_file_types, exclude, folders }) => {
      const payload = {
        allowed_file_types,
        exclude,
        folders,
      };
      mutate({ updates: payload });
    },
  });

  const handleExtCheck = (id, isChecked) => {
    if (isChecked.target.checked) {
      manualChange("allowed_file_types", [...data.allowed_file_types, id]);
    } else {
      manualChange(
        "allowed_file_types",
        data.allowed_file_types.filter((item) => item !== id)
      );
    }
  };

  const manualFolderChange = (key) => (e) => {
    manualChange(key, [e.target.value]);
  };

  const handleOpenPicker = (key) => {
    openPicker({
      viewId: "FOLDERS",
      token: orgIntegrationData.tokens,
      setIncludeFolders: true,
      setSelectFolderEnabled: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        let url = "";
        if (
          data.action === 'picked'
        ) {
          let doc = data.documents[0];
          url = doc.url;
          manualChange(key, [url]);  
        }
      },
    });
  };

  

  return (
    <form onSubmit={handleSubmit}>
      <div className=" border border-zinc-900/15 dark:border-white/10 divide-y divide-zinc-900/15 dark:divide-white/10 sm:rounded-lg bg-white/40 dark:bg-white/10">
        <div className="px-4 sm:px-9 divide-y divide-zinc-900/10 dark:divide-white/10">
          <div className="md:grid md:grid-cols-5 md:gap-6  pt-5 sm:pt-9 pb-4 ">
            <div className="md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50">
                Settings
              </h3>
              <p className="mt-1 text-sm dark:text-zinc-400 text-zinc-500">
                Configure your Google Drive Integration to indicate to the agent
                which file type to index and where to pull them from.
              </p>
            </div>
            <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
              <fieldset>
                <legend
                  htmlFor="extensiontypes"
                  className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
                >
                  File Extensions
                  <p className="mt-2 mb-4 text-sm font-normal dark:text-zinc-400 text-zinc-500 normal-case">
                    Select all the file extensions you want the agent to index
                    in your google drive. Not all file types are currently
                    supported.
                  </p>
                </legend>
                <Checkbox
                  label="PDF"
                  name="pdf"
                  id="pdf"
                  onChange={(isChecked) => handleExtCheck("pdf", isChecked)}
                  checked={data.allowed_file_types.includes("pdf")}
                />
              </fieldset>
              <div>
                <label
                  htmlFor="folders"
                  className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
                >
                  Selected Folders
                </label>
                <div className="flex flex-row">
                  <div className="flex-1">
                    <Input
                      id="folders"
                      name="folder"
                      type="text"
                      placeholder="/Shared/Company"
                      className="col-span-1"
                      error={errors.name}
                      value={data.name}
                      onChange={manualFolderChange("folders")}
                    />
                  </div>

                  <div className="ml-4 self-center">
                    <Button variant="outline" onClick={() => handleOpenPicker("folders")}>
                      Browse
                    </Button>
                  </div>
                </div>
                <p className="mt-2 mb-4 text-sm font-normal dark:text-zinc-400 text-zinc-500 normal-case">
                  Indicate which folders the crawler should index.
                </p>
              </div>

              <div>
                <label
                  htmlFor="excluded"
                  className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
                >
                  Excluded Folders
                </label>
                <div className="flex flex-row">
                  <div className="flex-1">
                    <Input
                      id="excluded"
                      name="excluded"
                      type="text"
                      placeholder="/Shared/Company/ExcludedFolderName"
                      className="col-span-1"
                      error={errors.name}
                      value={data.name}
                      onChange={manualFolderChange("exclude")}
                    />
                  </div>

                  <div className="ml-4 self-center">
                    <Button variant="outline" onClick={() => handleOpenPicker("exclude")}>
                      Browse
                    </Button>
                  </div>
                </div>
                <p className="mt-2 mb-4 text-sm font-normal dark:text-zinc-400 text-zinc-500 normal-case">
                  Indicate which folders the crawler <b>MUST NOT</b> index.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-row justify-end space-x-3 px-4 py-5 sm:px-9">
          <Button
            variant="secondary"
            type="button"
            onClick={() => resetForm()}
            disabled={!isDirty}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!isDirty}
            type="submit"
            loading={isUpdateLoading}
          >
            Update
          </Button>
        </div>
      </div>
    </form>
  );
};

const integrationMetadata = {
  google_drive: { image: "/gdrive.png", component: GoogleIntegrationDetails },
};

export const AgentIntegrationDetails = (props) => {
  const { agentId, integrationId } = useParams();
  const { fetcherFn } = useFetchContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data: agentIntegrations } = useQuery(
    [`agent/${agentId}/integration`],
    fetcherFn,
    {
      enabled: !!agentId,
    }
  );
  const { data: orgIntegrations } = useQuery([`integrations`], fetcherFn);

  const currIntegration =
    agentIntegrations &&
    agentIntegrations.find((int) => int.id === integrationId);
  const currOrgIntegration =
    orgIntegrations && orgIntegrations.find((int) => int.id === integrationId);
  const IntegrationCard = integrationMetadata[integrationId]?.component;

  return (
    <main className="mx-auto flex w-full min-w-[405px] max-w-7xl flex-col p-10">
      <button
        onClick={() => navigate(-1)}
        className="flex flex-row items-center space-x-2 text-sm dark:text-zinc-400 text-zinc-500"
      >
        <ArrowLeftIcon className="h-4 w-4" />{" "}
        <span className="font-medium">
          {state && state.origin
            ? `Back to ${state.origin} `
            : `Back to Agents`}
        </span>
      </button>
      <div className="mt-4 ml-6 flex flex-row">
        <div className="w-[64px] h-[64px] border rounded-lg border-zinc-900/15 dark:border-white/10 align-center mr-4 flex dark:bg-zinc-800">
          {/* <img src={org?.branding.logo_url} alt="Company logo" /> */}
          <div
            className="w-[60px] h-[60px] bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${integrationMetadata[integrationId].image})`,
            }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <h1 className="text-3xl font-medium text-zinc-900 dark:text-white">
            {currIntegration?.name}
          </h1>
          <div className="flex flex-row items-center space-x-2 text-sm dark:text-zinc-400 text-zinc-500">
            <span>Integration ID :</span>
            <div className="relative flex rounded shadow-sm ">
              <span className="inline-flex items-center rounded-l border border-r-0 border-zinc-900/7.5 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-1 dark:text-zinc-400 text-zinc-500">
                <FingerPrintIcon className="h-3 w-3" />{" "}
              </span>
              <span className="rounded-r border border-zinc-900/15 dark:border-white/10 bg-zinc-100 dark:bg-white/10 py-0.5 px-1 text-xs text-zinc-400 dark:text-white">
                {integrationId}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12">
        <IntegrationCard
          agentId={agentId}
          integrationData={currIntegration}
          orgIntegrationData={currOrgIntegration}
        />
      </div>
    </main>
  );
};
