import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useFetchContext } from "../contextProviders/FetchProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import { Prose } from "./Prose";

const ModuleSession = ({ data, sessionId, agent, firstCompletion = null }) => {
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [generatedResponseData, setGeneratedResponseData] =
    useState(firstCompletion);

  const [isTyping, setIsTyping] = useState(true);
  const [charCount, setCharCount] = useState(0);

  const [copied, setCopied] = useState(false);

  const copyResponse = () => {
    navigator.clipboard
      .writeText(generatedResponseData ? generatedResponseData : data?.response)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
  };

  const handleIsTyping = () => {
    return isTyping ? "█" : "";
  };

  const { mutate, isLoading: isCompletionLoading } = useMutation({
    mutationFn: ({ body, agentId }) => {
      return fetchWrapper(`agent/${agentId}/completion`, "POST", {}, body);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
      console.log("Error generating completion");
    },
    onSuccess: (completionData) => {
      if (completionData?.response)
        setGeneratedResponseData(completionData.response);
    },
  });

  useEffect(() => {
    if (generatedResponseData && isTyping) {
      const textLength = generatedResponseData.length || 0;
      let count = 0;

      const timer = setInterval(() => {
        if (count < textLength) {
          setCharCount((prevCount) => prevCount + 1);
          count++;
        } else {
          setIsTyping(false);
          clearInterval(timer);
        }
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedResponseData]);

  const handleRegenerate = () => {
    const body = {
      session_id: sessionId,
      // input: agent?.prompt || agent?.description || "General Info",
    };
    mutate({ body, agentId: agent.id });
  };

  return (
    <div className="mt-5 mb-8">
      <div className="rounded-t-md bg-[#E5E7EB] dark:bg-[#343541] p-4 flex justify-between">
        <span className="font-semibold">
          {data?.agent_display_name || agent.display_name}
        </span>
        {(generatedResponseData || data) && (
          <div className="flex">
            <button
              disabled={copied}
              onClick={() => copyResponse()}
              className="p-2 bg-gray-400 dark:bg-[#232329] rounded-md mr-1 hover:scale-105 ease-in duration-75"
            >
              {copied ? (
                <img width={15} src="/tick.png" alt="" />
              ) : (
                <img width={15} src="/SquareCopy.png" alt="" />
              )}
            </button>
            {isCompletionLoading ? (
              <div className="p-[0.3rem] bg-gray-400 dark:bg-[#232329] rounded-md  hover:scale-105 ease-in duration-75 flex items-center">
                <div className="spinner center">
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                </div>
              </div>
            ) : (
              <button
                className="p-2 bg-gray-400 dark:bg-[#232329] rounded-md  hover:scale-105 ease-in duration-75"
                onClick={handleRegenerate}
              >
                <img width={15} src="/ArrowReloader.png" alt="" />
              </button>
            )}
          </div>
        )}
      </div>
      <div
        className={`${
          isTyping && generatedResponseData && "animate-pulse"
        } rounded-b-md bg-white dark:bg-black max-h-200 p-4 overflow-y-auto custom-scrollbar text-gray-400 dark:text-white`}
      >
        {generatedResponseData ? (
          <Prose as="article" className="leading-3">
            <ReactMarkdown
              children={
                generatedResponseData.slice(0, charCount) + handleIsTyping()
              }
              remarkPlugins={[remarkGfm]}
            />
          </Prose>
        ) : (
          <Prose as="article" className="leading-3">
            <ReactMarkdown
              children={data?.response}
              remarkPlugins={[remarkGfm]}
            />
          </Prose>
        )}
      </div>
    </div>
  );
};

export default ModuleSession;
