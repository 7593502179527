import React from "react";
import { Prose } from "./Prose";

const UserPrompts = (props) => {
  const { prompt } = props;
  return (
    <div className="w-full border-b border-black/10 dark:border-[#444654]/40 text-zinc-900 dark:text-gray-100 group">
      <div className="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
        <div className="w-[30px] flex flex-col relative items-end">
          <div className="relative flex">
            <img
              className="inline-block h-8 w-8 rounded-md"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
          <div className="min-h-[20px] font-medium  flex flex-col items-start gap-4 whitespace-pre-wrap">
            <Prose>{prompt}</Prose>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPrompts;
