import { Tab } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

import { useGlobal } from "../contextProviders/GlobalProvider";
import { tabs } from "../utils/helpers/tabs";

const AgentDrawer = ({ agent }) => {
  const {
    drawer,
    globalAgent,
    selectedTabDrawer,
    setSelectedTabDrawer,
    hasAdminRole,
  } = useGlobal();

  const tabsToShow = hasAdminRole
    ? tabs
    : tabs.filter((tab) => tab.id === "summary" || tab.id === "files");

  if (!agent) return null;

  const handleOnChange = (index) => {
    setSelectedTabDrawer(index);
  };

  return (
    <Tab.Group
      className="mx-6 mt-8 w-full"
      as="nav"
      selectedIndex={selectedTabDrawer}
      onChange={handleOnChange}
    >
      <Tab.List className="-mb-px flex flex-row space-x-6 border-b border-zinc-900/15 dark:border-white/10">
        {tabsToShow.map((tab) => (
          <Tab className="" aria-label="Tabs" as={Fragment} key={tab.id}>
            {({ selected }) => (
              <div
                key={tab.name}
                className={clsx(
                  selected
                    ? "border-purple-500 dark:text-white"
                    : "border-transparent text-zinc-500 hover:border-zinc-300 hover:text-zinc-700",
                  "cursor-pointer whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                )}
              >
                {tab.name}
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="py-8" as="div">
        {tabsToShow.map((tab) => {
          return (
            <Tab.Panel key={`panel_${tab.id}`}>
              <tab.component
                data={globalAgent.find((ag) => ag.id === drawer.agentId)}
              />
            </Tab.Panel>
          );
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default AgentDrawer;
