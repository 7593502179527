import React, { useEffect, useState } from "react";

//internal
import { useFetchContext } from "../contextProviders/FetchProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import useForm from "../utils/hooks/useForms";
import { Input, SearchCombobox } from "./Input";
import { useGlobal } from "../contextProviders/GlobalProvider";
import { CONSTANTS } from "../utils/helpers";

export const Member = (props) => {
  const { setIsOpen, orgId } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);
  const { drawer, setDrawer } = useGlobal();

  const { DRAWER_TYPE } = CONSTANTS;

  const sendInvite = async (inviter, email, roles, groups) => {
    setIsLoading(true);

    const payload = {
      inviter_name: inviter,
      email,
      roles:
        roles?.length > 0 ? roles.split(",").map((value) => value.trim()) : [],
      groups:
        groups?.length > 0
          ? groups.split(",").map((value) => value.trim())
          : [],
    };

    if (drawer.type == DRAWER_TYPE.EDIT_MEMBER) {
      try {
        await fetchWrapper(
          `account/${drawer.member.user_id}`,
          "PATCH",
          {},
          { payload }
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await fetchWrapper(`account/invite/${orgId}`, "POST", {}, payload);
        const params = {
          success: true,
          msg: "User successfully Invited!",
        };
        emitNotification(params);
        setIsLoading(false);
        return params;
      } catch (err) {
        const params = {
          success: false,
          msg: "Something went wrong!",
        };
        emitNotification(params);
        setIsLoading(false);
        return params;
      }
    }
  };

  const { handleSubmit, handleChange, resetForm, data, errors } = useForm({
    initialValues: {
      inviter_name: "",
      firstname: "",
      lastname: "",
      email: "",
      roles: { id: "ORG_MEMBER", name: "Member" },
      groups: "",
    },
    validationDebounce: 500,
    validations: {
      firstname: {
        required: {
          value: true,
          message: "Inviter Name required.",
        },
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9'.]+([\p{L}\p{M}\p{Zs}0-9'\-.&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      lastname: {
        required: {
          value: true,
          message: "Inviter Name required.",
        },
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9'.]+([\p{L}\p{M}\p{Zs}0-9'\-.&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      email: {
        required: {
          value: true,
          message: "Display Name required.",
        },
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/gu,
          message: "Invalid email.",
        },
      },
    },
    onSubmit: async ({ firstname, lastname, email, roles, groups }) => {
      const results = await sendInvite(
        firstname + lastname,
        email,
        roles.id,
        groups
      );
      if (results.success) {
        drawer.action();
        closeModal();
      }
    },
  });

  const closeModal = () => {
    setDrawer({
      isOpen: false,
    });
    setIsOpen(false);
    resetForm();
  };

  const roles = [
    { id: "ORG_MEMBER", name: "Member" },
    { id: "ORG_ADMIN", name: "Administrator" },
  ];

  useEffect(() => {
    if (drawer?.type == DRAWER_TYPE.EDIT_MEMBER) {
      resetForm({
        email: drawer?.member?.email,
        firstname: drawer?.member?.name?.split(" ")[0],
        lastname: drawer?.member?.name?.split(" ")[1],
        roles: { id: "ORG_MEMBER", name: "Member" },
      });
    }
  }, [drawer?.type]);

  return (
    <div>
      <div className="w-full">
        <form className="" onSubmit={handleSubmit}>
          <div className="relative mx-2 mt-4 space-y-6">
            {drawer?.type == DRAWER_TYPE.ADD_MEMBER ? (
              <img
                src="/avatar.png"
                className="mx-auto"
                alt=""
                width={120}
                height={120}
              />
            ) : drawer?.member?.picture ? (
              <img
                src={drawer?.member?.picture}
                className="mx-auto h-32 w-32 rounded-full"
                alt=""
                width={120}
                height={120}
              />
            ) : (
              <div className="h-32 w-32 mx-auto dark:bg-white/10 bg-zinc-200/70 rounded-full text-4xl text-zinc-900 dark:text-white flex justify-center items-center">
                <span>{drawer?.member?.name?.split(" ")[0].charAt(0)}</span>
                <span>{drawer?.member?.name?.split(" ")[1].charAt(0)}</span>
              </div>
            )}

            <Input
              id="firstname"
              name="firstname"
              type="text"
              required
              placeholder="First Name"
              error={errors.firstname}
              value={data.firstname}
              onChange={handleChange("firstname", true)}
            />
            <Input
              id="lastname"
              name="lastname"
              type="text"
              required
              placeholder="Last Name"
              error={errors.lastname}
              value={data.lastname}
              onChange={handleChange("lastname", true)}
            />
            {/* <Input
              id="identifier"
              name="identifier"
              type="text"
              placeholder="Identifier"
              error={errors.identifier}
              value={data.identifier}
              onChange={handleChange("identifier", true)}
            /> */}
            <Input
              id="email"
              name="email"
              type="text"
              required
              label="Email"
              placeholder="Email"
              error={errors.email}
              value={data.email}
              onChange={handleChange("email", true)}
            />
            <SearchCombobox
              data={roles}
              id="member_role"
              label="Role"
              value={data.roles}
              onChange={handleChange("roles", true)}
              error={errors.roles}
            />
          </div>

          <div className="mt-5 sm:mt-6 sm:flex sm:justify-end">
            <div className="sm:flex sm:flex-row-reverse gap-4">
              <button
                type="submit"
                className="text-white px-4 py-2 rounded-md bg-purple-600"
                loading={isLoading}
              >
                Save
              </button>
              <button
                type="button"
                className="border-zinc-300 bg-white dark:bg-white/10 dark:hover:bg-white/5 dark:border-white/10 px-4 py-1 text-base font-medium text-zinc-400 shadow-sm hover:bg-zinc-100  hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-300 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
