import { useState, useEffect } from 'react';
import { useHandler } from './useHandler';

const useForm = options => {
  const [initialData, setInitial] = useState(options?.initialValues || {});
  const [data, setData] = useState(options?.initialValues || {});
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  const validationFn = async (value, key, updateErrorState = false) => {
    const validations = options?.validations;
    const validation = validations[key];
    let valid = true;
    let error = '';
    
      if (validation?.required?.value && (value === undefined || value.length === 0)) {
        valid = false;
        error = validation?.required?.message;
      }

    if (value && value.length > 0) {
      const pattern = validation?.pattern;
      if (pattern?.value && !RegExp(pattern.value).test(value)) {
        valid = false;
        error = pattern.message;
      }
    }

      const custom = validation?.custom;

      if (custom?.isValid) {
        const fn = await custom.isValid(value, data);
        if (!fn) {
          valid = false;
          error = custom.message;
        }
      }
    

    if (updateErrorState) {
      setErrors({
        ...errors,
        [key]: error,
      });
    }

    return {
      valid,
      error,
    };
  };

  const debouncedValidation = useHandler(
    (value, key, updateErrorState = false) => {
      validationFn(value, key, updateErrorState);
    },
    { debounce: options?.validationDebounce || 0 }
  );

  const runValidations = async (subset = []) => {
    const validations = options?.validations;
    if (validations) {
      const array = subset.length === 0 ? Object.keys(validations) : subset;
  
      let valid = true;
      const newErrors = {};
  
      for (const key of array) {
        const value = data[key];
        const result = await validationFn(value, key);
        if (!result.valid) {
          valid = false;
        }
        newErrors[key] = result.error;
      }
  
      if (!valid) {
        setErrors(newErrors);
        return false;
      }
  
      setErrors({});
      return true;
    }
  };  

  const handleChange = (key, runValidation, sanitizeFn) => (e) => {
    const rawValue = e.target['type'] === 'checkbox' ? e.target.checked : e.target.value;
    const value = sanitizeFn ? sanitizeFn(rawValue) : rawValue;

    setData({
      ...data,
      [key]: value,
    });

    if (runValidation) {
      debouncedValidation(value, key, true);
    }
  };

  const manualChange = (key, val, resetInit, sanitizeFn) => {
    const value = sanitizeFn ? sanitizeFn(val) : val;
    setData({
      ...data,
      [key]: value,
    });

    if (resetInit) {
      setInitial({
        ...data,
        [key]: value,
      });
    }
  };

  const resetForm = (newInitial) => {
    let data;
    if (newInitial) {
      data = newInitial;
    } else {
      data = initialData;
    }
    setData(data);
  };

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(initialData)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [data, initialData]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const valid = await runValidations()

    if (valid && options?.onSubmit) {
      setIsDirty(false);
      await options.onSubmit(data);
    }
    setLoading(false);
  };

  return {
    data,
    isLoading,
    handleChange,
    manualChange,
    handleSubmit,
    runValidations,
    resetForm,
    isDirty,
    setIsDirty,
    errors
  };
};

export default useForm;
