import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { FetchProvider } from "./contextProviders/FetchProvider";
import { GlobalContextProvider } from "./contextProviders/GlobalProvider";
import AgentPicker from "./routes/AgentPicker";
import Chat from "./routes/Chat";
import Session from "./routes/Session";
import { AgentDetails } from "./routes/admin/agents/AgentDetails";
import { AgentIntegrationDetails } from "./routes/admin/agents/AgentIntegrationDetails";
import { Agents } from "./routes/admin/agents/Agents";
import { Integrations } from "./routes/admin/integrations/Integrations";
import { Organization } from "./routes/admin/organization/Organization";
import { Organizations } from "./routes/super-admin/Organizations";
import { CONSTANTS } from "./utils/helpers";

const { USER_ROLE } = CONSTANTS;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 5000, //Data will be considered fresh for at least 5min
    },
  },
});

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const ProtectedRouteAuth = ({ allowedRoles }) => {
  const { user } = useAuth0();
  const {
    organization: { roles },
  } = user;

  return allowedRoles.find((role) => roles.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      <GoogleOAuthProvider clientId="430567727840-pkpc633r8lqf9tv05oee429jdcrve2ar.apps.googleusercontent.com">
        {children}
      </GoogleOAuthProvider>
    </Auth0Provider>
  );
};

function App() {
  // TODO: Create staging and prod environments for issuer/Auth0
  let audience = "https://api.telios.io";

  if (
    window.location.host === "staging.telios.io" ||
    window.location.host === "localhost:3000"
  ) {
    audience = "https://api-staging.telios.io";
  }

  return (
    <Auth0ProviderWithRedirectCallback
      domain="dev-35v1a2fdsguxagnr.us.auth0.com"
      clientId="HC8NDSXFQmsqqovRSOgqlcATTjSaSDVD"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        issuer: "https://dev-35v1a2fdsguxagnr.us.auth0.com/",
      }}
    >
      <FetchProvider>
        <GlobalContextProvider>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<ProtectedRoute component={Layout} />}>
                <Route index element={<AgentPicker />} />
                <Route path=":agentId" element={<Chat />} />
                <Route
                  path="admin"
                  element={
                    <ProtectedRouteAuth
                      allowedRoles={[
                        USER_ROLE.ORG_ADMIN,
                        USER_ROLE.SUPER_ADMIN,
                      ]}
                    />
                  }
                >
                  <Route path="agents" element={<Agents />} />
                  <Route path="agents/:agentId" element={<AgentDetails />} />
                  <Route
                    path="agents/:agentId/integration/:integrationId"
                    element={<AgentIntegrationDetails />}
                  />
                  <Route path="organization" element={<Organization />} />
                  <Route path="integrations" element={<Integrations />} />
                </Route>
                <Route
                  path="super-admin"
                  element={
                    <ProtectedRouteAuth
                      allowedRoles={[USER_ROLE.SUPER_ADMIN]}
                    />
                  }
                >
                  <Route path="organizations" element={<Organizations />} />
                  <Route
                    path="organizations/:orgId"
                    element={<Organization />}
                  />
                </Route>
                <Route path="session" element={<Outlet />}>
                  <Route path=":id" element={<Session />} />
                </Route>
              </Route>
            </Routes>
            {/* Dev tool is automatically excluded during prod builds */}
            <ReactQueryDevtools />
          </QueryClientProvider>
        </GlobalContextProvider>
      </FetchProvider>
    </Auth0ProviderWithRedirectCallback>
  );
}

export default App;
