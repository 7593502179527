import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useMemo, useState } from "react";

import { functions } from "../utils/helpers";

const GlobalContext = createContext();

function GlobalContextProvider({ children }) {
  const [globalAgent, setGlobalAgent] = useState(null);
  const [isLoadingSessionList, setIsLoadingSessionList] = useState(false);
  const [sessionsList, setSessionsList] = useState(null);
  const [globalModal, setGlobalModal] = useState({
    action: () => {},
    isOpen: false,
    onClose: () => {},
    title: "",
    subtitle: "",
    type: "",
    titleValue: "",
  });
  const [drawer, setDrawer] = useState({
    type: "",
    isOpen: false,
    title: "",
    agentId: "",
    jsonData: null,
  });
  const [selectedTabDrawer, setSelectedTabDrawer] = useState(0);
  const { getAdminRole, getSuperAdminRole } = functions;
  const { user } = useAuth0();

  const hasAdminRole = useMemo(() => {
    if (user && user.organization.roles) {
      return getAdminRole(user.organization.roles);
    }
    return false;
  }, [user, getAdminRole]);

  const hasSuperAdminRole = useMemo(() => {
    if (user && user.organization.roles) {
      return getSuperAdminRole(user.organization.roles);
    }
    return false;
  }, [user, getSuperAdminRole]);

  const contextValue = useMemo(
    () => ({
      globalAgent,
      setGlobalAgent,
      hasAdminRole,
      hasSuperAdminRole,
      globalModal,
      setGlobalModal,
      sessionsList,
      setSessionsList,
      isLoadingSessionList,
      setIsLoadingSessionList,
      drawer,
      setDrawer,
      selectedTabDrawer,
      setSelectedTabDrawer,
    }),
    [
      globalAgent,
      setGlobalAgent,
      hasAdminRole,
      hasSuperAdminRole,
      globalModal,
      setGlobalModal,
      sessionsList,
      setSessionsList,
      isLoadingSessionList,
      setIsLoadingSessionList,
      drawer,
      setDrawer,
      selectedTabDrawer,
      setSelectedTabDrawer,
    ]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}

function useGlobal() {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within a GlobalContextProvider");
  }
  return context;
}

export { GlobalContextProvider, useGlobal };
