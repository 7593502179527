const extensionToIconMap = {
  pdf: "file_pdf",

  doc: "file_doc",
  docx: "file_doc",
  txt: "file_doc",
  rtf: "file_doc",

  xls: "file_xls",
  xlsx: "file_xls",
  csv: "file_xls",

  ppt: "file_ppt",
  pptx: "file_ppt",
  key: "file_ppt",

  jpg: "file_img",
  jpeg: "file_img",
  jpe: "file_img",
  png: "file_img",
  tif: "file_img",
  tiff: "file_img",
  gif: "file_img",
  bmp: "file_img",

  html: "file_html",
  xml: "file_script",
  md: "file_script",
  js: "file_script",
  py: "file_script",
  java: "file_script",
  cpp: "file_script",
  css: "file_script",

  mp3: "file_music",
  wav: "file_music",
  aac: "file_music",
  ogg: "file_music",

  mp4: "file_video",
  mov: "file_video",
  m4v: "file_video",
  flv: "file_video",
  wmv: "file_video",
  avi: "file_video",

  zip: "file_obj",
  rar: "file_obj",
};

const getFileExtension = (fileName) => {
  const parts = fileName.split(".");
  return parts[parts.length - 1].toLowerCase();
};

const FileIcon = ({ fileName = "" }) => {
  const fileExtension = getFileExtension(fileName);

  const icon = extensionToIconMap[fileExtension] || "file_obj";

  return <img src={`/${icon}.png`} alt="" className="m-2 h-8" />;
};

export default FileIcon;
