import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowUpTrayIcon,
  ChevronDownIcon
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { motion, useScroll, useTransform } from "framer-motion";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useGlobal } from "../contextProviders/GlobalProvider";
import Logo from "./Logo";
import { ModeToggle } from "./ModeToggle";

function TopLevelNavItem({ href, children }) {
  return (
    <li>
      <Link
        to={href}
        className="text-sm leading-5 text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white"
      >
        {children}
      </Link>
    </li>
  );
}

export const Header = forwardRef(function Header({ className }, ref) {
  // let { isOpen: mobileNavIsOpen } = useMobileNavigationStore()
  // let isInsideMobileNavigation = useIsInsideMobileNavigation()
  const auth = useAuth0();
  const { picture, nickname, name } = auth.user;
  const { hasAdminRole, hasSuperAdminRole } = useGlobal();

  let { scrollY } = useScroll();
  let bgOpacityLight = useTransform(scrollY, [0, 72], [0.5, 0.9]);
  let bgOpacityDark = useTransform(scrollY, [0, 72], [0.2, 0.8]);

  const popoverRef = useRef(null);
  const targetRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const handleSignOut = () => {
    auth.logout({ logoutParams: { returnTo: window.location.origin } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        targetRef.current &&
        !targetRef.current.contains(event.target)
      ) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePopover = () => setShowPopover(!showPopover);

  return (
    <motion.div
      ref={ref}
      className={clsx(
        className,
        "fixed inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-4 transition sm:px-6 lg:z-30 lg:px-8 border-b border-zinc-900/5 dark:border-white/2.5",
        //   !isInsideMobileNavigation &&
        "backdrop-blur-sm dark:backdrop-blur lg:left-72 xl:left-80",
        //   isInsideMobileNavigation
        //     ? 'bg-white dark:bg-zinc-900'
        "bg-white/[var(--bg-opacity-light)] dark:bg-zinc-900/[var(--bg-opacity-dark)]"
      )}
      style={{
        "--bg-opacity-light": bgOpacityLight,
        "--bg-opacity-dark": bgOpacityDark,
      }}
    >
      <div
        className={clsx(
          "absolute inset-x-0 top-full h-px transition",
          // (isInsideMobileNavigation || !mobileNavIsOpen) &&
          "bg-zinc-900/7.5 dark:bg-white/7.5"
        )}
      />
      {showPopover && (
        <div
          ref={popoverRef}
          className={clsx(
            "absolute z-30 flex flex-col",
            "dark:text-white bg-white dark:bg-[#1D2730] backdrop-blur-sm dark:backdrop-blur",
            "w-[200px] top-full right-0 border dark:border-white/7.5 rounded"
          )}
        >
          {/* <div className="flex pt-3 pb-3 cursor-pointer border-b dark:border-b-white/7.5 hover:bg-gray-200 dark:hover:bg-zinc-700">
            <Cog8ToothIcon className="h-4 w-4 mr-2 ml-3 self-center" />
            <span>My Settings</span>
          </div>
          <Link
            to="/support"
            className="flex py-3 cursor-pointer border-b dark:border-b-white/7.5 hover:bg-gray-200 dark:hover:bg-zinc-700"
          >
            <UserIcon className="h-4 w-4 mr-2 ml-3 self-center" />{" "}
            <span>Support</span>
          </Link> */}
          <div
            className="flex py-3 cursor-pointer hover:bg-gray-200 dark:hover:bg-zinc-700"
            onClick={handleSignOut}
          >
            <ArrowUpTrayIcon className="h-4 w-4 mr-2 ml-3 rotate-90 self-center" />
            <span>Sign Out</span>
          </div>
        </div>
      )}
      <div />
      {/* <Search /> */}
      {/* <div></div> */}
      <div className="flex items-center gap-5 lg:hidden">
        {/* <MobileNavigation /> */}
        <Link href="/" aria-label="Home" className="flex flex-row items-center">
          <Logo className="h-6" />
          {/* <span className="text-zinc-900 text-2xl dark:text-white ml-3 font-black tracking-wider" style={{fontFamily: 'Montserrat'}}>TELIOS</span> */}
        </Link>
      </div>
      <div className="flex items-center gap-5">
        <nav className="hidden md:block">
          <ul role="list" className="flex items-center gap-8">
            <TopLevelNavItem href="/">Home</TopLevelNavItem>
            {hasAdminRole && (
              <TopLevelNavItem href="/admin/agents">
                {hasSuperAdminRole ? "Super Admin" : "Admin"}
              </TopLevelNavItem>
            )}
            <div className="flex gap-4">
              {/* <MobileSearch /> */}
              <ModeToggle />
            </div>
            <TopLevelNavItem>
              <div ref={targetRef} className="flex" onClick={handlePopover}>
                <img
                  className="h-8 w-8 rounded-full"
                  src={picture}
                  alt={nickname}
                />
                <div className="ml-2 mr-3 self-center">{name}</div>
                <ChevronDownIcon
                  className={clsx(
                    showPopover ? "rotate-180 transform" : "",
                    "w-4 h-4 self-center"
                  )}
                />
              </div>
            </TopLevelNavItem>
          </ul>
        </nav>
        {/* <div className="hidden md:block md:h-5 md:w-px md:bg-zinc-900/10 md:dark:bg-white/15" /> */}
        <div className="hidden min-[416px]:contents">
          {/* <Button to="/" plus="left">
            New Chat
          </Button> */}
        </div>
      </div>
    </motion.div>
  );
});
