import { ChatBubbleLeftIcon, CheckIcon, MagnifyingGlassIcon, PlusSmallIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

function ArrowIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"
      />
    </svg>
  )
}

const variantStyles = {
  primary:
    'rounded-full bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-purple-400/10 dark:text-purple-400 dark:ring-1 dark:ring-inset dark:ring-purple-400/20 dark:hover:bg-purple-400/10 dark:hover:text-purple-300 dark:hover:ring-purple-300',
  secondary:
    'rounded-full bg-zinc-200 py-1 px-3 font-normal text-zinc-500  hover:text-zinc-700 hover:bg-zinc-300 dark:bg-zinc-800 hover:dark:bg-zinc-800/50 dark:text-zinc-400 dark:ring-1 dark:ring-inset dark:ring-zinc-800 dark:hover:bg-zinc-800 dark:hover:text-zinc-300',
  filled:
    'rounded-full bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-purple-500 dark:text-white dark:hover:bg-purple-400',
  outline:
    'rounded-full py-1 px-3 text-zinc-700 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white',
  text: 'text-white hover:bg-zinc-300/10 w-full px-3',
  danger:
  'rounded-full bg-red-700 py-1 px-6 text-white hover:bg-red-800 hover:text-white/80 text-sm font-semibold',
  purple:
    'rounded-full py-1 px-3 bg-purple-400/10 ring-1 ring-inset ring-purple-400/20 hover:bg-purple-400/10 text-purple-400 hover:text-purple-300 hover:ring-purple-300',
}

export function Button({
  variant = 'primary',
  className,
  children,
  arrow,
  plus,
  search,
  chat,
  userplus,
  check,
  loading = false,
  ...props
}) {
  let Component = props.to ? Link : 'button'

  className = clsx(
    'inline-flex gap-0.5 overflow-hidden text-sm font-medium transition items-center cursor-pointer',
    variantStyles[variant],
    className
  )

  const spinnerClass=clsx('text-white dark:text-purple-400', "animate-spin h-5 w-5")

  let arrowIcon = (
    <ArrowIcon
      className={clsx(
        'mt-0.5 h-5 w-5',
        variant === 'text' && 'relative top-px',
        arrow === 'left' && '-ml-1 rotate-180',
        arrow === 'right' && '-mr-1'
      )}
    />
  )

  return (
    <Component className={className} {...props}>
      {!loading && 
        <span>
          {arrow === 'left' && arrowIcon}   
          {plus === 'left' && <PlusSmallIcon className='mr-1 h-4 w-4'/>}
          {search === 'left' && <MagnifyingGlassIcon className='mr-1 h-4 w-4'/>}
          {chat === 'left' && <ChatBubbleLeftIcon className='mr-2 h-5 w-5'/>}
          {userplus === 'left' && <UserPlusIcon className='mr-1 h-4 w-4'/>}
          {check === 'left' && <CheckIcon className='mr-1 h-4 w-4'/>}
        </span>
      }
      {loading && <svg className={spinnerClass} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>}
      <span>{children}</span>
      <span>
      {arrow === 'right' && arrowIcon}
      {plus === 'right' && <PlusSmallIcon className='ml-1 h-4 w-4'/>}
      {search === 'right' && <MagnifyingGlassIcon className='ml-1 h-4 w-4'/>}
      {check === 'right' && <CheckIcon className='ml-1 h-4 w-4'/>}
      </span>
    </Component>
  )
}
