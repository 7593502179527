import React, { Fragment, useState } from "react";

//external librairies
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  ChevronDownIcon,
  CodeBracketIcon,
  Square3Stack3DIcon,
  SunIcon,
  SwatchIcon,
  WindowIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// INTERNAL
import Loader from "../../../components/Loader";
import { useFetchContext } from "../../../contextProviders/FetchProvider";
import { Button } from "../../../components/Button";
import { Input, Textarea } from "../../../components/Input";
import { useNotificationContext } from "../../../contextProviders/NotificationProvider";
import { CONSTANTS } from "../../../utils/helpers";
import useForm from "../../../utils/hooks/useForms";

const { LLL_MODELS } = CONSTANTS;

const Row = (props) => {
  const { data } = props;
  const { id, name, display_name, createdAt } = data;

  const navigate = useNavigate();

  const goToView = () => {
    navigate(`./${id}`);
  };

  return (
    <tr
      key={id}
      className="hover:bg-zinc-100 dark:hover:bg-white/5 cursor-pointer bg-white dark:bg-white/2.5"
      onClick={goToView}
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          {/* <div className="h-10 w-10 flex-shrink-0">
                <img className="h-10 w-10 rounded-full" src={person.picture} alt="" />
                </div> */}
          <div className="">
            <div className="font-medium dark:text-zinc-300 hover:text-purple-500 dark:hover:text-purple-500">
              {display_name}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-400">
        <div>{name}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-zinc-600 dark:text-zinc-400">
        <span className="bg-zinc-200 dark:bg-white/20 py-0.5 px-1 rounded border border-zinc-300 dark:border-zinc-900/15 text-zinc-500 dark:text-zinc-300">
          {id}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-400">
        {/* <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                Active
            </span> */}
        {createdAt && moment(createdAt).fromNow()}
      </td>
      <td className="relative whitespace-nowrap py-4 pr-3 text-right text-sm font-medium"></td>
    </tr>
  );
};

const Modal = (props) => {
  const { isOpen, setIsOpen } = props;
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [selectedModel, setSelectedModel] = useState(LLL_MODELS[0]);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ payload }) => {
      return fetchWrapper("agent", "POST", {}, payload);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [`agent`],
      });
      const params = {
        success: true,
        msg: "Agent successfully created!",
      };
      emitNotification(params);
    },
    onSettled: () => {
      closeModal();
    },
  });

  const {
    handleSubmit,
    handleChange,
    resetForm,
    data,
    errors,
  } = useForm({
    initialValues: {
      name: "",
      display_name: "",
      description: "",
      prompt: "",
      temperature: 0,
      top_k: 10,
      logo_url: "",
    },
    validationDebounce: 500,
    validations: {
      name: {
        required: {
          value: true,
          message: "Name required.",
        },
        pattern: {
          value: /^[a-z_0-9]+$/gu,
          message: "Invalid entry. The name must be all be snake_case.",
        },
      },
      display_name: {
        required: {
          value: true,
          message: "Display Name required.",
        },
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9/'.]+([\p{L}\p{M}\p{Zs}0-9/'\-&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      description: {
        pattern: {
          value:
            /^[\p{L}\p{M}\p{Zs}0-9][\p{L}\p{M}\p{Zs}0-9/'.]+([\p{L}\p{M}\p{Zs}0-9/'\-&]+)*/gu,
          message:
            "Invalid entry. Only letters, numbers and the following special characters are allowed - & . ' allowed.",
        },
      },
      prompt: {
        required: {
          value: false,
          message: "Prompt required.",
        },
      },
      temperature: {
        required: {
          value: true,
          message: "Temperature required.",
        },
        pattern: {
          // value:
          //   /^(?!0+$)[1-9]\d*$/gu,
          // message:
          //   "Invalid entry. Must be a number greater than 0",
          value: /^(?!-1+$)\d\d*$/g,
          message: "Invalid entry. Must be a number equal or greater than 0",
        },
      },
      top_k: {
        required: {
          value: true,
          message: "Top K required.",
        },
        pattern: {
          value: /^(?!0+$)[1-9]\d*$/gu,
          message: "Invalid entry. Must be a number greater than 0",
        },
      },
    },
    onSubmit: async ({
      name,
      display_name,
      description,
      prompt,
      temperature,
      top_k,
      logo_url,
    }) => {
      const payload = {
        name,
        description,
        display_name,
        prompt,
        tools: {
          redact: true,
        },
        llm: {
          name: "openai",
          model: selectedModel?.name || selectedModel,
          temperature,
        },
        top_k,
        logo_url,
      };
      mutate({ payload });
    },
  });

  const closeModal = () => {
    setIsOpen(false);
    resetForm();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 pt-40 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-zinc-800 p-8 text-left align-middle shadow-xl transition-all">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-zinc-800 text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-50"
                >
                  Agent Registration
                </Dialog.Title>
                <div className="my-3">
                  <p className="text-sm dark:text-zinc-400 text-zinc-500">
                    Register a new Agent in order to create a chatbot against a
                    new data source or for another use case.
                  </p>
                </div>
                <form className="" onSubmit={handleSubmit}>
                  <div className="relative mx-2 mt-4 space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        id="display_name"
                        name="diplay_name"
                        type="text"
                        required
                        label="Display Name"
                        placeholder="Chatbot"
                        className="col-span-1"
                        error={errors.display_name}
                        value={data.display_name}
                        prepend={
                          <WindowIcon className="h-4 w-4 text-zinc-500" />
                        }
                        onChange={handleChange("display_name", true)}
                      />
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        required
                        label="Name"
                        placeholder="chatbot"
                        error={errors.name}
                        value={data.name}
                        className="col-span-1"
                        prepend={
                          <CodeBracketIcon className="h-4 w-4 text-zinc-500" />
                        }
                        onChange={handleChange("name", true)}
                      />
                    </div>
                    <Textarea
                      id="description"
                      name="description"
                      label="Description"
                      rows={2}
                      placeholder="A description about Chatbot..."
                      error={errors.description}
                      value={data.description}
                      onChange={handleChange("description", true)}
                    />
                    <Textarea
                      id="prompt"
                      name="prompt"
                      label="Model Prompt"
                      placeholder="You are a virtual chatbot that answers general questions about things."
                      error={errors.prompt}
                      value={data.prompt}
                      onChange={handleChange("prompt", false)}
                    />
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-6">
                        <label
                          htmlFor={"model"}
                          className="block text-sm font-medium text-slate-700 dark:text-white capitalize"
                        >
                          Model
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm relative dark:shadow-md ">
                          <Listbox
                            value={selectedModel}
                            onChange={setSelectedModel}
                          >
                            {({ open }) => (
                              <div className="relative w-full max-w-lg m-auto">
                                <Listbox.Button className="relative rounded w-full h-10 cursor-default rounded-r-md flex bg-white/50 dark:bg-zinc-800/50 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-zinc-500 focus:outline-none  dark:focus:outline-none focus:ring-2 dark:focus:ring-2 focus:ring-purple-400 dark:focus:ring-purple-400 sm:text-sm sm:leading-6">
                                  <span className="inline-flex h-[inherit] items-center rounded-l border border-r-0 border-zinc-900/15 dark:border-white/10 bg-zinc-300/50 dark:bg-white/5 px-3 dark:text-zinc-400 text-zinc-500 sm:text-sm">
                                    <SwatchIcon className="h-4 w-4 text-zinc-500" />
                                  </span>
                                  <span className="block truncate ml-4 my-auto dark:text-white text-slate-700">
                                    {selectedModel?.name || selectedModel}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                      className="h-4 w-4 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[16.9rem] overflow-auto rounded-md bg-white dark:bg-zinc-800 py-1 text-xs shadow-lg ring-1 ring-gray-300 dark:ring-zinc-500 focus:outline-none sm:text-sm bottom-10">
                                    {LLL_MODELS.map((model) => (
                                      <Listbox.Option
                                        key={model.name}
                                        className={({ active }) =>
                                          clsx(
                                            active
                                              ? "dark:bg-zinc-700 bg-zinc-100"
                                              : "",
                                            "text-gray-900 dark:text-white relative cursor-pointer select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={model.name}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={clsx(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {model.name}
                                            </span>

                                            {selected ? (
                                              <span className="dark:text-white text-gray-900 absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon
                                                  className="h-6 w-6 font-bold"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            )}
                          </Listbox>
                          <div className="absolute -bottom-6 left-3 flex items-center justify-start text-xs">
                            <div className="text-red-600">{errors.model}</div>
                          </div>
                        </div>
                      </div>
                      <Input
                        id="temperature"
                        className="col-span-3"
                        name="temperature"
                        type="text"
                        required
                        label="Temperature"
                        placeholder="0"
                        error={errors.temperature}
                        value={data.temperature}
                        prepend={<SunIcon className="h-4 w-4 text-zinc-500" />}
                        onChange={handleChange("temperature", true)}
                      />
                      <Input
                        id="top_k"
                        className="col-span-3"
                        name="top_k"
                        type="text"
                        required
                        label="Top K"
                        placeholder="10"
                        error={errors.top_k}
                        value={data.top_k}
                        prepend={
                          <Square3Stack3DIcon className="h-4 w-4 text-zinc-500" />
                        }
                        onChange={handleChange("top_k", true)}
                      />
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:flex sm:justify-end">
                    <div className="sm:flex sm:flex-row-reverse gap-4">
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Register Agent
                      </Button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-full border border-zinc-300 bg-white dark:bg-white/10 dark:hover:bg-white/5 dark:border-white/10 px-4 py-1 text-base font-medium text-zinc-400 shadow-sm hover:bg-zinc-100  hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-300 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const Agents = () => {
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const { fetcherFn } = useFetchContext();
  const {
    data: agentList,
    isLoading,
  } = useQuery(["agent"], fetcherFn, {
    staleTime: 60000,
    keepPreviousData: true,
  });

  return (
    <>
      <main className="mx-auto flex w-full min-w-[405px] max-w-7xl flex-col p-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-zinc-900 dark:text-white">
                Agents
              </h1>
              <p className="mt-2 text-sm dark:text-zinc-400 text-zinc-500">
                Create a new Agent to or select an existing agent to modify it.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                type="button"
                onClick={() => setIsRegistrationModalOpen(true)}
                plus="left"
              >
                Add Agent
              </Button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="rounded-xl overflow-hidden border border-zinc-900/15 dark:border-white/10">
                  <table className="min-w-full divide-y divide-zinc-900/15 dark:divide-white/10 ">
                    <thead className=" dark:bg-white/10 bg-zinc-200/70">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-white sm:pl-6"
                        >
                          Display Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                        >
                          Identifier
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-white"
                        >
                          Created Date
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-zinc-900/15 dark:divide-white/10">
                      {!isLoading &&
                        agentList &&
                        Array.isArray(agentList) &&
                        agentList?.map((agent) => (
                          <Row key={agent.id} data={agent} />
                        ))}
                      {isLoading && (
                        <tr>
                          <td colSpan="6">
                            <div className="my-20 flex w-full justify-center">
                              <Loader className="h-10 w-10 text-zinc-400" />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        isOpen={isRegistrationModalOpen}
        setIsOpen={setIsRegistrationModalOpen}
      />
    </>
  );
};
