import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import Loader from "../components/Loader";
import SubAgentSession from "../components/SubAgentSession";
import { useFetchContext } from "../contextProviders/FetchProvider";
import { useGlobal } from "../contextProviders/GlobalProvider";
// import { useNotificationContext } from "../contextProviders/NotificationProvider";

const Session = () => {
  const { id } = useParams();
  const { fetcherFn } = useFetchContext();
  // const [generatedResponseData, setGeneratedResponseData] = useState(null);
  const { globalAgent } = useGlobal();
  // const filesIndex = tabs.findIndex((tab) => tab.id === "files");

  const { data, isLoading, isFetching } = useQuery(
    [`session/${id}`],
    fetcherFn,
    {
      // staleTime: 60000,
      keepPreviousData: false,
      enabled: !!id,
    }
  );

  if (isLoading || isFetching)
    return (
      <div className="flex justify-center mt-20">
        <Loader className="h-10 w-10" />
      </div>
    );

  return globalAgent && globalAgent?.length > 0
    ? globalAgent.map((agent) => {
        const turns = data?.turns?.length
          ? data.turns.filter((elm) => elm.agent_id === agent.id)
          : [];
        return <SubAgentSession agent={agent} turns={turns} key={agent.id} />;
      })
    : null;
};

export default Session;
